import { Radio, drawerClasses, SwipeableDrawer, styled, Stack, Button, Grid, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import PrimaryButton from '@components/buttons/PrimaryButton';

const CustomRadio = styled(Radio)(({ }) => ({
}));

const SwipeableDrawerComp = styled(SwipeableDrawer)(() => {
  return {
    [`&.${drawerClasses.root}`]: {
      zIndex: 1500
    },
    [`&.${drawerClasses.root} .${drawerClasses.paper}`]: {
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24
    }
  };
});

interface ISelectDrawerProps {
  open: boolean;
  onClose: () => void;
  selected: string | null;
  onChange: (value: string) => void;
  options: Array<any>;
  name: string;
  submitBtnLabel: string;
  heading: string;
}

export default function SelectDrawer({ open, onClose, onChange, selected, options, name, submitBtnLabel, heading }: ISelectDrawerProps) {
  const [selectedLocal, setSelectedLocal] = useState<any>();

  useEffect(()=>{
    setSelectedLocal(selected);
  }, [selected]);

  return <SwipeableDrawerComp
    anchor={'bottom'}
    open={open}
    onClose={() => { }}
    onOpen={() => { }}
  >
    <Grid container pt={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: 2
          }}
        >
          <Typography variant="h5" fontWeight={500}>
            {heading}
          </Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ border: '1px solid #EDF1F3', my: 1 }}></Box>
      </Grid>
    </Grid>
    <Stack spacing={3} sx={{ py: 3, px: 2 }}>
      {options.map((o, idx) => {
        return <Stack key={idx} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{o.label ?? o.name}</Typography>
          <CustomRadio
            checked={selectedLocal == o.value || selectedLocal === o.name}
            onChange={(e: any) => {
              console.log(e.target.value)
              setSelectedLocal(e.target.value);
              if(name === 'category-value' && o.subCategories) {
                onChange(e.target.value);
              }
            }}
            value={o.value ?? o.name}
            name={name}
          />
        </Stack>
      })}
      <PrimaryButton isSmall={true} btnText={submitBtnLabel} onClick={()=>onChange(selectedLocal)} />
    </Stack>
  </SwipeableDrawerComp>
}