import {
  Box,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Typography,
  linearProgressClasses,
  styled
} from '@mui/material';
import { getColorByText, getInitials } from '@utils/helpers';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IProps {
  name: string;
  description: any;
  onClick: () => void;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 24,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E6ECEF'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#27AB83'
  }
}));

export default function WorkOrderCard({
  name,
  description,
  onClick
}: IProps) {
  const renderNameTag = (name: string) => {
    return (
      <Box
        sx={{
          width: '32px',
          height: '32px',
          lineHeight: '32px',
          border: '1px solid #00000014',
          borderRadius: '50%',
          textAlign: 'center',
          background: getColorByText(name),
          color: '#fff'
        }}
      >
        {getInitials(name)}
      </Box>
    );
  };

  return (
    <Grid
      container
      sx={{
        p: 2,
        background: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 6px 24px 0px #3034361A'
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            gap: 1
            // marginBottom: '0.5rem'
          }}
        >
          {renderNameTag(name)}
          <Box ml={1}>
            <Typography sx={{fontSize: '16px', lineHeight: '16px', fontWeight: 500}}>{name}</Typography>
            <Typography variant="caption" color={'#5A6A72'}>
              {description}
            </Typography>
          </Box>
          <Box color={'#57BD83'} onClick={onClick} sx={{ cursor: 'pointer' }}>
            <OpenInNewIcon sx={{width: '16px', height: '16px'}}/>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
