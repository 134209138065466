import { InputBase, Select as MuiSelect, styled } from "@mui/material";
import palette from "./palette";

const Input = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      borderRadius: 4,
      padding: ".5rem .75rem",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
      border: "1px solid",
      borderColor: palette.grey[50],
      borderRadius: ".5rem",
    },
  }));
  
  export const Select = ({ children, ...props }: any) => {
    return (
      <MuiSelect input={<Input/>} {...props}>
        {children}
      </MuiSelect>
    );
  };