import PageHeader from '@components/PageHeader';
import {
  Box,
  Grid,
  Paper,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import { ButtonCover } from '@ui/button';
import { useAuth } from '@contexts/AuthContext';
import { getRequest, putRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';


const Table = styled(MuiTable)(({theme})=>({
}))

export default function ManagePropertiesPage() {
  const isMobile = useIsMobile();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  const [rows, setRows] = useState<any[]>([]);
  const {user, updateProfile} = useAuth();

  useEffect(() => {
    getProperties();
  },[]);

  const getProperties = async() => {
    const propResp = await getRequest(`properties/all`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    setRows(propResp.data);
  }

  const updateProperty = async(id: string, isActive: boolean, code: string) => {
    await putRequest(`properties/${id}`, {isActive}, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    await getProperties();
    await updateProfile();
    if(isActive) {
      await getRequest(`work-orders/load?init=true&p=${code}`)
    }
  }

  return (
    <>
      <Box>
        <PageHeader
          title={<FormattedMessage id="Manage Properties" />}
        />
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 'rgba(48, 52, 54, 0.1) 0px 6px 24px 0px',
            borderRadius: isMobile ? 'none' : '1rem',
            mt: 5,
            mb: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {isMobile && (
                  <TableCell
                    sx={{
                      borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                      color: '#8D9DA5'
                    }}
                  >
                    <Typography variant="body1" color={'#8D9DA5'}>
                      Properties
                    </Typography>
                  </TableCell>
                )}
                {!isMobile && (
                  <>
                    <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                        color: '#8D9DA5'
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                        color: '#8D9DA5'
                      }}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3'
                      }}
                    ></TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice((paginationModel.page * paginationModel.pageSize), (paginationModel.page * paginationModel.pageSize + paginationModel.pageSize)).map((row, index) => (
                <TableRow key={index}>
                  {isMobile && (
                    <TableCell
                      scope="row"
                      sx={{
                        borderBottom: '1px solid #EDF1F3'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                gap: 1
                              }}
                            >
                              <Typography color={'#5A6A72'}>
                                {row.name}
                              </Typography>
                            </Box>
                            <Typography color={'#5A6A72'}>
                              {row.isActive ? 'Active' : 'Inactive'}
                              </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{ cursor: 'pointer', pr: isMobile ? 0 : 2 }}
                          onClick={() => {
                            const cnf = confirm(`Are you sure you want to ${row.isActive ? 'Deactivate' : 'Activate'} this property`);
                            if(cnf) {
                              updateProperty(row.id, !row.isActive, row.code);
                            }
                          }}
                        >
                          <Typography variant="body1" color={'#57BD83'}>
                            {row.isActive ? 'Deactivate' : 'Activate'}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  )}
                  {!isMobile && (
                    <>
                      <TableCell
                        scope="row"
                        sx={{
                          borderBottom: '1px solid #EDF1F3'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            marginBottom: '1rem',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          <Typography>{row.name}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '1rem' }}>{row.isActive ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <ButtonCover onClick={() => {
                          const cnf = confirm(`Are you sure you want to ${row.isActive ? 'Deactivate' : 'Activate'} this property`);
                          if(cnf) {
                            updateProperty(row.id, !row.isActive, row.code);
                          }
                        }}>
                          <Typography variant="body1" color={'#57BD83'}>
                            {row.isActive ? 'Deactivate' : 'Activate'}
                          </Typography>
                        </ButtonCover>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 5
            }}
          >
            <TablePagination
              component={'div'}
              count={rows.length}
              page={paginationModel.page}
              rowsPerPage={paginationModel.pageSize}
              labelRowsPerPage={'Rows'}
              onPageChange={(e, page) =>
                setPaginationModel({
                  page: page,
                  pageSize: paginationModel.pageSize
                })
              }
              onRowsPerPageChange={(e) =>
                setPaginationModel({
                  page: 0,
                  pageSize: parseInt(e.target.value, 10)
                })
              }
            />
          </Box>
        </Grid>
      </Box>

    </>
  );
}
