import { Grid, IconButton } from '@mui/material';
import TextInput from '@components/inputs/TextInput';
import SelectInput from '@components/inputs/SelectInput';
import DateInput from '@components/inputs/DateInput';
import { Control } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import AttributionIcon from '@mui/icons-material/Attribution';
import FlagIcon from '@mui/icons-material/Flag';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import { useIsMobile } from '@hooks/useIsMobile';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface IOptionProps {
  id: any;
  name: string;
  description?: string;
  color?: string;
  icon?: React.ReactNode;
}

interface IGridSearchProps {
  control: Control<any, any>;
  statusOptions?: IOptionProps[];
  searchOptions?: boolean;
  techOptions?: IOptionProps[];
  priorityOptions?: IOptionProps[];
  serviceTypeOptions?: IOptionProps[];
  dateOptions?: boolean;
  unitOptions?: IOptionProps[];
  onFilter?: any;
  selectedValues?: any;
}

export default function GridSearch({
  control,
  statusOptions,
  searchOptions,
  techOptions,
  priorityOptions,
  serviceTypeOptions,
  dateOptions,
  unitOptions,
  onFilter,
  selectedValues
}: IGridSearchProps) {
  const isMobile = useIsMobile();
  const [searchVisibility, setSearchVisibility] = useState(!isMobile);

  useEffect(() => {
    setSearchVisibility(searchOptions ?? false);
  }, [searchOptions]);

  return (
    <Grid container spacing={isMobile ? 0 : 2} pb={2}>
      {searchVisibility && (
        <Grid item xs={11} sm={isMobile ? 11 : 4} md={isMobile ? 11 : 3} lg={isMobile ? 11 : 2}>
          <TextInput
            name="search"
            placeholder="Search"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Search is required'
              },
              maxLength: {
                value: 50,
                message: 'Search is too long'
              }
            }}
            type="text"
            style={{
              background: '#FFF'
            }}
            startIcon={<SearchIcon />}
            onBlur={onFilter}
          />
        </Grid>
      )}
      {isMobile && searchVisibility && (
        <Grid item xs={1} style={{ paddingLeft: 0 }}>
          <IconButton
            color="inherit"
            sx={{ top: '20px' }}
            onClick={() => {
              setSearchVisibility(!searchVisibility);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
      {!isMobile && (
        <>
          {techOptions && (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <SelectInput
                name="technician"
                placeholder="Any Tech"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={techOptions}
                btnLabel="Save Changes"
                startIcon={<AttributionIcon />}
                btnClick={onFilter}
                selectedValue={selectedValues.technician}
              />
            </Grid>
          )}
          {priorityOptions && (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <SelectInput
                name="priority"
                placeholder="Any Priority"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={priorityOptions}
                btnLabel="Save Changes"
                startIcon={<FlagIcon />}
                btnClick={onFilter}
                selectedValue={selectedValues.priority}
              />
            </Grid>
          )}
          {serviceTypeOptions && (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <SelectInput
                name="serviceType"
                placeholder="Any Service Type"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={serviceTypeOptions}
                btnLabel="Save Changes"
                startIcon={<BuildIcon />}
                btnClick={onFilter}
                selectedValue={selectedValues.serviceType}
              />
            </Grid>
          )}
          {dateOptions && (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <DateInput
                name="date"
                placeholder="Any Date"
                control={control}
                startIcon={<CalendarTodayIcon />}
                btnClick={onFilter}
                btnLabel="Save Changes"
              />
            </Grid>
          )}
          {unitOptions && unitOptions.length && (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <SelectInput
                name="unit"
                placeholder="Any Unit"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={unitOptions}
                btnLabel="Save Changes"
                startIcon={<HomeIcon />}
                btnClick={onFilter}
                selectedValue={selectedValues.unit}
              />
            </Grid>
          )}
        </>
      )}
      {isMobile && (
        <>
          {statusOptions && (
            <Grid item xs={12} >
              <SelectInput
                name="status"
                placeholder="Select Status"
                label="Status"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={statusOptions}
                btnLabel="Filter"
                startIcon={<BuildIcon />}
                isAccordion
                selectedValue={selectedValues.status}
              />
            </Grid>
          )}
          {serviceTypeOptions && (
            <Grid item xs={12} >
              <SelectInput
                name="serviceType"
                placeholder="Select Service Type"
                label="Service Type"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={serviceTypeOptions}
                btnLabel="Filter"
                startIcon={<BuildIcon />}
                isAccordion
                selectedValue={selectedValues.serviceType}
              />
            </Grid>
          )}
          {techOptions && (
            <Grid item xs={12} >
              <SelectInput
                name="technician"
                placeholder="Select Tech"
                label="Technician"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={techOptions}
                btnLabel="Filter"
                startIcon={<AttributionIcon />}
                isAccordion
                isExpand
                selectedValue={selectedValues.technician}
              />
            </Grid>
          )}
          {priorityOptions && (
            <Grid item xs={12} >
              <SelectInput
                name="priority"
                placeholder="Select Priority"
                label="Priority"
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={priorityOptions}
                btnLabel="Filter"
                startIcon={<FlagIcon />}
                isAccordion
                isCard
                selectedValue={selectedValues.priority}
              />
            </Grid>
          )}
          {dateOptions && (
            <Grid item xs={12} >
              <DateInput
                name="date"
                placeholder="Select Date"
                label="Date Range"
                control={control}
                startIcon={<CalendarTodayIcon />}
              />
            </Grid>
          )}
          {unitOptions && unitOptions.length && (
            <Grid item xs={12} >
              <SelectInput
                name="unit"
                placeholder="Select a Unit"
                label={<FormattedMessage id="Unit Number"/>}
                control={control}
                type="Multiple"
                defaultValues={[]}
                options={unitOptions}
                btnLabel="Filter by Unit(s)"
                startIcon={<HomeIcon />}
                isExpand
                isAccordion
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
