import { ReactNode } from 'react';
import { Card } from '@ui/card';
import { Box, Stack, Typography } from '@mui/material';

interface IProps {
    title?: string | ReactNode;
    data?: string;
    description?: string;
    icon?: ReactNode;
    iconBgColor?: string;
    isMobile?: boolean;
    cardSx?: any;
    onClick?: any;
}

export default function SummaryCard({
    title,
    data,
    description,
    icon,
    iconBgColor,
    isMobile,
    cardSx,
    onClick
}: IProps) {
    return <Card sx={{ height: '100%', ...(isMobile ? { backgroundColor: iconBgColor, px: 1.5, pt: 1.5, pb: 2 } : {}), ...cardSx }} onClick={onClick}>
        {!isMobile ? <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: .5 }}>
            <Typography variant='body1'>{title || '   '}</Typography>
            { icon && <Box sx={{
                borderRadius: '.5rem',
                p: 1.5,
                height: '3rem',
                width: '3rem',
                backgroundColor: iconBgColor,
                '& svg': {
                    height: '1.5rem',
                    width: '1.5rem'
                }
            }}>{icon}</Box> }
        </Stack> : null}
        <Typography sx={{
            fontSize: isMobile ? '1.5rem' : '2.5rem',
            lineHeight: isMobile ? 1.33 : 1.1,
            fontWeight: 500,
            letterSpacing: '-1.5px',
            mb: isMobile ? .5 : 2
        }}>{data || 'N/A'}</Typography>
        {isMobile ? <Typography variant="body2">{title || '  '}</Typography> : <Typography variant="body1" sx={{ color: '#5A6A72' }}>{description || '  '}</Typography>}
    </Card>
}
