import {
    Stack,
    Typography,
    Dialog,
    DialogContent,
} from '@mui/material';
import { ReactComponent as NewLogo } from '@assets/images/new-logo.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';

export default function Success({ open, onClose }: any) {
    return <>
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogContent sx={{ px: 4 }}>
                <Stack spacing={5}>
                    <Typography variant='h3' sx={{ mb: 3 }}>Work Order Created</Typography>
                    <Stack justifyContent="center" alignItems="center">
                        <NewLogo />
                    </Stack>
                    <PrimaryButton btnText="Back to Work Orders" onClick={onClose} />
                </Stack>
            </DialogContent>
        </Dialog>
    </>
} 