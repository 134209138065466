import AnalyticsCard from '@components/cards/AnalyticsCard';
import SummaryCard from '@components/cards/SummaryCard';
import PageHeader from '@components/PageHeader';
import {
  Box,
  Grid,
  InputBase,
  MenuItem,
  Stack,
  Select,
  styled,
  Typography,
  Button,
  IconButton,
  SwipeableDrawer,
  drawerClasses,
  Radio
} from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import GraphCard from '@components/cards/GraphCard';
import TechnicianDistributionChart from '@components/charts/technician-distribution-chart';
import WorkOrderBreakDownChart from '@components/charts/work-order-breakdown-chart';
import WorkOrderIntakeChart from '@components/charts/work-order-intake-chart';
import ServiceTypeBreakDown from '@components/tables/service-type-breakdown';
import PropertySelector from '@components/inputs/PropertySelect';
import { useIsMobile } from '@hooks/useIsMobile';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Select as CustomSelect } from '@utils/select';
import palette from '@utils/palette';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import SelectDrawer from '@components/drawers/SelectDrawer';
import { getRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';

export const MobileSelect = styled(InputBase)(({ }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    background: '#9AD8B4'
  },
  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
    borderRadius: '1rem'
  },
  '& .MuiSvgIcon-root': {
    height: '2rem',
    width: '2rem',
    marginLeft: '.375rem'
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    padding: '.375rem 2rem .375rem .75rem !important',
    fontSize: '.875rem',
    lineHeight: 1.43,
    fontWeight: 600
  },
  '&.MuiInputBase-root .MuiSvgIcon-root': {
    width: '1.25rem',
    height: '1.25rem'
  }
}));

enum DrawerType {
  DateFilter = 'DateFilter',
  PropertyFilter = 'PropertyFilter'
}

const dateOptions = [
  { value: '1', label: 'Past 24 hours' },
  { value: '7', label: 'Past 7 days' },
  { value: '14', label: 'Past 14 days' },
  { value: '30', label: 'Past 30 days' },
  { value: '90', label: 'Past 90 days' },
];

export const getTime = (timeSpent: number) => {
  let spent = ''
  if (timeSpent > 1440) {
      spent += `${Math.floor(timeSpent / 1440)}d `;
      timeSpent = timeSpent % 1440;
  }
  if (timeSpent > 60) {
      spent += `${Math.floor(timeSpent / 60)}h `;
      timeSpent = timeSpent % 60;
  }
  if (timeSpent > 0) {
      spent += `${timeSpent}m `;
  }
  return spent;
}

export default function DashboardPage() {
  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });
  const [activeSlide, setActiveSlide] = useState(0);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [seeAllTA, setSeeAllTA] = useState(false);
  const { user, setUser, updateTechList } = useAuth();
  const [pastDays, setPastDays] = useState<string>('30');

  const [userJobs, setUserJobs] = useState<any[]>([]);

  const [summary, setSummary] = useState<{
    workOrder: null | string;
    avgTimeToFinish: null | string;
    callbacks: null | string;
    openWorkOrders: null | string;
  }>({
    workOrder: null,
    avgTimeToFinish: null,
    callbacks: null,
    openWorkOrders: null
  });

  const [graphsData, setGraphsData] = useState(null as any);

  const getDashboardData = async () => {
    let url = `dashboard/${user?.selectedProperty.id}?pastDays=${pastDays}`;
    const resp = await getRequest(url, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    setSummary({
      workOrder: resp.data.newWos,
      avgTimeToFinish: getTime(resp.data.avgTimeToFinish),
      callbacks: resp.data.callbacks,
      openWorkOrders: resp.data.openWorkOrders
    });
    let url1 = `dashboard/${user?.selectedProperty.id}/graph?pastDays=${pastDays}`;
    const resp1 = await getRequest(url1, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    setGraphsData(resp1.data);
  }

  useEffect(() => {
    setUserJobs(user!!.techUsers);
    getDashboardData();
  }, [pastDays, user?.selectedProperty]);

  const jobs = useMemo(() => {
    return (seeAllTA ? userJobs : userJobs?.slice(0, 4)) || [];
  }, [seeAllTA, userJobs])

  return (
    <Box>
      <PageHeader
        title={<FormattedMessage id="Dashboard" />}
        selection={(user?.properties?.length || 0) > 1 && <PropertySelector />}
        action={
          <CustomSelect
            value={pastDays}
            sx={{
              '& .MuiInputBase-input': {
                padding: '.675rem .75rem',
                background: palette.white
              }
            }}
            IconComponent={ExpandMoreRoundedIcon}
            onChange={(e: any, v: any) => {
              setPastDays(e.target.value)
            }}
          >
            {dateOptions.map(d => {
              return <MenuItem value={d.value}>{d.label}</MenuItem>
            })}
          </CustomSelect>
        }
      />
      {isMobile ? (
        <Stack direction="row" spacing={2} sx={{ backgroundColor: palette.white, mx: -2, px: 2, mt: -4, pt: 4, mb: -2, pb: 2 }}>
          <Box onClick={() => {
            setDrawer({
              open: true,
              type: DrawerType.DateFilter
            })
          }}>
            <Select
              sx={{
                pointerEvents: 'none'
              }}
              value={pastDays}
              input={<MobileSelect />}
              IconComponent={ExpandMoreRoundedIcon}
            >
              {dateOptions.map(d => {
                return <MenuItem value={d.value}>{d.label}</MenuItem>
              })}
            </Select>
          </Box>
          <Box onClick={() => {
            setDrawer({
              open: true,
              type: DrawerType.PropertyFilter
            })
          }}>
            { (user?.properties?.length || 0) > 1 ? <PropertySelector
              sx={{
                pointerEvents: 'none',
                maxWidth: '200px',
                overflow: 'hidden'
              }} input={<MobileSelect />}
              renderValue={(displayValue) => (value: any) => displayValue}
            /> : '' }
          </Box>
        </Stack>
      ) : null}
      <Box sx={{ boxShadow: '0px 2px 8px -8px rgba(48, 52, 54, 0.08)', mb: 5, ...(isMobile ? { backgroundColor: palette.white, mx: -2, px: 2, pb: 2 } : {}) }}>
        <Grid container spacing={3} sx={{ mt: isMobile ? 1 : 0 }}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard cardSx={{cursor: 'pointer'}} onClick={() => navigate('/work-orders')}
              isMobile={isMobile}
              title={<FormattedMessage id="Open Work Orders" />}
              data={summary.openWorkOrders!!}
              // description="+8.4% Compared to Past 7 days"
              icon={<NewReleasesOutlinedIcon />}
              iconBgColor="#EDF1F3"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard
              isMobile={isMobile}
              title={<FormattedMessage id="New Work Orders" />}
              data={summary.workOrder!!}
              // description="+8.4% Compared to Past 7 days"
              icon={<NewReleasesOutlinedIcon />}
              iconBgColor="#EDF1F3"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard
              isMobile={isMobile}
              title={<FormattedMessage id="Avg. Time to Finish" />}
              data={summary.avgTimeToFinish!!}
              // description="+8.4% Compared to past 7 days"
              icon={<TimerOutlinedIcon />}
              iconBgColor="#CEE6FF"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard
              isMobile={isMobile}
              title={<FormattedMessage id="Call Backs" />}
              data={summary.callbacks!!}
              // description="+12% Compared to past 7 days"
              icon={<PhoneInTalkOutlinedIcon />}
              iconBgColor="#FFF3C4"
            />
          </Grid>
        </Grid>
      </Box>


      <Box sx={{ mb: 5 }}>
        <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ mb: 5 }}>
        {<FormattedMessage id="Graphs and Charts" />}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <GraphCard title={<FormattedMessage id="Work Order Intake" />} sx={{ height: '100%' }}>
              <WorkOrderIntakeChart graphsData={graphsData} />
            </GraphCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <GraphCard title={<FormattedMessage id="Work Order Breakdown" />} sx={{ height: '100%' }}>
              <WorkOrderBreakDownChart graphsData={graphsData} />
            </GraphCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <GraphCard
              title={<FormattedMessage id="Technician Distribution (Top 4)" />}
              sx={{ height: '100%' }}
            >
              <TechnicianDistributionChart graphsData={graphsData} />
            </GraphCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <GraphCard title={<FormattedMessage id="Service Type Breakdown" />} sx={{ height: '100%' }}>
              <ServiceTypeBreakDown graphsData={graphsData} />
            </GraphCard>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{mb: 2}}><FormattedMessage id="Technician Analytics" /></Typography>
          {(!seeAllTA && !isMobile) && <Button onClick={() => setSeeAllTA(true)}>
            <Typography
              variant="h4"
              fontWeight={400}
              letterSpacing={-1}
              sx={{ color: '#57BD83 ' }}
            >
              <FormattedMessage id="See All" />
            </Typography>
          </Button>}
        </Box>

        {isMobile ?
          <Carousel
            showStatus={false}
            centerMode={true}
          >
            {(userJobs || []).map((job, idx) => (
              <Box sx={{ mr: 3 }} key={idx}>
                <AnalyticsCard
                  user={job}
                  data={graphsData}
                />
              </Box>

            ))}
          </Carousel>
          : <Grid container spacing={2}>
            {jobs.map((job, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
                <AnalyticsCard
                  user={job}
                  data={graphsData}
                />
              </Grid>
            ))}
          </Grid>}
      </Box>
      {
        isMobile ?
          <>
            <SelectDrawer
              open={drawer.open && drawer.type === DrawerType.PropertyFilter}
              onClose={() => {
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              options={(user || [] as any).properties.map((p: any) => ({ label: p.name, value: p.id }))}
              onChange={(value) => {
                const sp = (user?.properties || []).find((p) => p.id == (value));
                updateTechList(sp);
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              name="property-filter"
              selected={user!!.selectedProperty.id}
              submitBtnLabel='View Property'
              heading="Select Property to View"
            />
            <SelectDrawer
              open={drawer.open && drawer.type === DrawerType.DateFilter}
              onClose={() => {
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              options={dateOptions}
              onChange={(value) => {
                setPastDays(value);
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              name="date-filter"
              selected={pastDays || null}
              submitBtnLabel='Select Date Range'
              heading="Select Date Range"
            /></> : ''
      }

    </Box >
  );
}
