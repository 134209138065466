import {
    Stack,
    Typography,
    Dialog,
    DialogContent,
    Card
} from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { ButtonCover } from '@ui/button';

export const OptionCard = ({ title, description, sx = {} }: any) => {
    return <Card sx={{
        boxShadow: 'none',
        borderRadius: '.5rem',
        border: '1px solid rgba(48, 52, 54, 0.35)',
        py: 1.5,
        px: 2,
        width: '100%',
        '&:hover': {
            background: '#d6f9e5', border: '2px solid #4eaa76'
        }, ...sx
    }}>
        <Typography variant='h5' sx={{
            color: '#2b363b',
            mb: 1
        }}>{title}</Typography>
        <Typography variant='body2' sx={{ color: '#5a6a72' }}>{description}</Typography>
    </Card>
}

export default function CreateWOTypeSelect({ open, onClose, openPreventiveWO, openTenantWO }: any) {
    const onSelect = (type: number) => {
        if (type === 1) {
            openTenantWO();
        } else if (type === 2) {
            openPreventiveWO();
        }
    }
    return <>
        <Dialog open={open} onClose={onClose} maxWidth="md" sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
                borderRadius: '1rem'
            }
        }}>
            <DialogContent sx={{ px: 5, py: 3, borderRadius: '1rem' }}>
                <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                    <ButtonCover onClick={onClose}>
                        <ArrowBackIosRoundedIcon sx={{ height: '1.25rem', width: '1.25rem', color: '#5a6a72', mr: 1 }} />
                        <Typography variant="body1" sx={{ color: '#5a6a72' }} onClick={onClose}>Back</Typography>
                    </ButtonCover>
                </Stack>
                <Typography variant='h3' sx={{ mb: 3 }}>Select Work Order Type</Typography>
                <Stack spacing={2}>
                    <ButtonCover sx={{ width: '100%', textAlign: 'left' }} onClick={() => onSelect(1)}>
                        <OptionCard
                            title="Resident Work Order"
                            description="Work Orders relating to an apartment unit."
                        />
                    </ButtonCover>
                    <ButtonCover sx={{ width: '100%', textAlign: 'left' }} onClick={() => onSelect(2)}>
                        <OptionCard
                            title="Preventative Work"
                            description="Tasks done on a weekly basis."
                        />
                    </ButtonCover>
                </Stack>
            </DialogContent>
        </Dialog>
    </>
} 