import { useIsMobile } from "@hooks/useIsMobile";
import { Card, Typography } from "@mui/material";
import { ReactNode } from "react";

export default function GraphCard({ title, children, sx }: { title: any, children: ReactNode, sx?: any }) {
    const isMobile = useIsMobile();
    return <Card sx={{
        boxShadow: '0 .1875rem 1.5rem 0 rgba(48, 52, 54, 0.10)',
        px: isMobile ? 2.5 : 3,
        pt: isMobile ? 2.5 : 3,
        pb: 2.5,
        borderRadius: '1rem',
        ...(sx || {})
    }}>
        <Typography variant={ isMobile ? 'body1' : 'h5' } sx={{ fontWeight: 500, mb: 3 }}>{title}</Typography>
        {children}
    </Card>
}