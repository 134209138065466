import { styled } from "@mui/material/styles";
import { TextField as MuiTextField } from "@mui/material";
import palette from "@utils/palette";

export const TextField = styled(MuiTextField)({
  "& .MuiInputBase-input": {
    padding: ".75rem 1.25rem",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: '#30343624',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: '#30343624',
      borderRadius: ".5rem",
    },
    "&:not(.Mui-error):hover fieldset": {
      borderColor: '#30343624',
    },
    "&.Mui-focused:not(.Mui-error) fieldset": {
      borderColor: '#30343624',
    },
  },

  "& .MuiInputLabel-root": {
    transform: "translate(16px, 12px) scale(1)",
    "&.Mui-focused, &.MuiFormLabel-filled": {
      transform: "translate(16px, -9px) scale(0.75)",
    },
  },
  "& .MuiInputLabel-root:not(.Mui-error)": {
    color: palette.grey[40],
  },
  "& label": {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  '& input': {
    borderRadius: ".5rem",
  }
});

export const SearchBox = styled(MuiTextField)({
    "& .MuiInputBase-input": {
      padding: ".75rem 1.25rem .75rem 0",
      '&::-webkit-input-placeholder': {
          fontSize: '1rem',
          lineHeight: 1.5,
          display: 'flex',
          alignItems: 'center'
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.grey[50],
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: palette.grey[50],
        borderRadius: "1.5rem",
      },
      "&:not(.Mui-error):hover fieldset": {
        borderColor: palette.grey[50],
      },
      "&.Mui-focused:not(.Mui-error) fieldset": {
        borderColor: palette.grey[50],
      },
    },
  
    "& .MuiInputLabel-root": {
      transform: "translate(16px, 12px) scale(1)",
      "&.Mui-focused, &.MuiFormLabel-filled": {
        transform: "translate(16px, -9px) scale(0.75)",
      },
    },
    "& .MuiInputLabel-root:not(.Mui-error)": {
      color: palette.grey[40],
    },
    "& label": {
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: 400,
      // color: palette.grey[40],
    },
  });