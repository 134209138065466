import { useState } from "react"
import CreateWOTypeSelect from "./create-wo-type-select";
import TenantIssueWO from "./tenantIssueWO";
import PreventiveWO from "./preventiveWO";

enum DialogType {
    'CreateWOTypeSelect' = 'CreateWOTypeSelect',
    'TenantIssueWO' = 'TenantIssueWO',
    'PreventiveWO' = 'PreventiveWO'
}

export default function CreateWO({ onClose }: any) {
    const [dialog, setDialog] = useState<{
        open: boolean,
        type: DialogType | null
    }>({
        open: true,
        type: DialogType.CreateWOTypeSelect
    });

    return <>
        <CreateWOTypeSelect open={dialog.open && dialog.type === DialogType.CreateWOTypeSelect} onClose={() => {
            setDialog({
                open: false,
                type: null
            });
            onClose();
        }} openTenantWO={() => setDialog({
            open: true,
            type: DialogType.TenantIssueWO
        })} openPreventiveWO={() => setDialog({
            open: true,
            type: DialogType.PreventiveWO
        })} />
        {dialog.open && dialog.type === DialogType.TenantIssueWO && <TenantIssueWO open={dialog.open && dialog.type === DialogType.TenantIssueWO} handleClose={() => {
            setDialog({
                open: false,
                type: null
            });
            onClose();
        }} />}
        {dialog.open && dialog.type === DialogType.PreventiveWO && <PreventiveWO open={dialog.open && dialog.type === DialogType.PreventiveWO} handleClose={() => {
            setDialog({
                open: false,
                type: null
            });
            onClose();
        }} />}
    </>
}