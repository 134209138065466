import { createTheme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const theme = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: '#2B363B',
      white: '#FFFFFF'
    },
    primary: {
      light: '#e1fbee',
      main: '#57BD83',
      dark: '#57BD8333'
    },
    secondary: {
      light: '#323232',
      main: '#FFFFFF',
      dark: '#8D9DA5'
    },
    warning: {
      main: '#FFC130'
    },
    success: {
      main: '#86CC6E'
    },
    error: {
      main: '#F82B2B'
    },
    text: {
      primary: '#2B363B',
      secondary: '#2B363B',
      disabled: '#8D9DA5'
    },
    action: {
      disabledBackground: '#57BD8333',
      disabled: '#8D9DA5',
      focus: '#fff',
      hover: '#fff',
      active: '#2B363B'
    },
    background: {
      paper: '#fff',
      default: '#f5f8fa'
    }
  },
  typography: {
    fontFamily: ['Public Sans', 'Roboto'].join(','),
    h1: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '52px',
      lineHeight: '56px',
      fontWeight: 700
    },
    h2: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '42px',
      fontWeight: 700
    },
    h3: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 500
    },
    h4: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 500
    },
    h5: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 600
    },
    body1: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400
    },
    body2: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400
    },
    subtitle1: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700
    },
    subtitle2: {
      color: '#2B363B',
      fontFamily: 'Public Sans',
      fontSize: '10px',
      lineHeight: '18px',
      fontWeight: 400
    },
    overline: {
      fontFamily: 'Public Sans',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 700,
      letterSpacing: '1.25px',
      textTransform: 'uppercase'
    },
    button: {
      fontFamily: 'Public Sans',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '1.25px',
      textTransform: 'none'
    }
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#8d9da5b8'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFF',
          color: '#2B363B',
          border: '1px solid #30343614'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          //border: 'none'
        }
      }
    }
  }
});
