import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  TextField,
  Typography
} from '@mui/material';
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
  StaticDatePicker
} from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useIsMobile } from '@hooks/useIsMobile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IAccordionProps {
  label: string;
  fromDateFilter: string;
  setFromDateFilter: (event: any) => void;
  toDateFilter: string;
  setToDateFilter: (event: any) => void;
  setSelectedIndex: (event: any) => void;
}

const DateInAccordion = ({
  label,
  fromDateFilter,
  setFromDateFilter,
  toDateFilter,
  setToDateFilter,
  setSelectedIndex
}: IAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedFrom, setExpandedFrom] = useState<boolean>(false);
  const [expandedTo, setExpandedTo] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      sx={{ boxShadow: 'none' }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          px: 0
        }}
      >
        <Typography variant="body1" fontWeight={500} color={'#5A6A72'}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, px: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color={'#303436BF'} pb={0.5}>
              From
            </Typography>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DesktopDatePicker
                value={DateTime.fromISO(fromDateFilter).toLocal()}
                onChange={(value) => {
                  value && setFromDateFilter(value.toString());
                }}
                onOpen={() => {
                  setExpandedFrom(!expandedFrom);
                }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => {
                      setSelectedIndex(-1);
                      setFromDateFilter('');
                      setExpandedFrom(!expandedFrom);
                    }
                  },
                  textField: {
                    placeholder: 'Select Date',
                    fullWidth: true
                  }
                }}
              />
              <Accordion
                sx={{ boxShadow: 'none', m: '0 !important' }}
                expanded={expandedFrom}
              >
                <AccordionSummary
                  sx={{
                    minHeight: '0 !important'
                  }}
                ></AccordionSummary>
                <AccordionDetails sx={{ pt: 0, px: 0 }}>
                  <StaticDatePicker
                    defaultValue={DateTime.fromISO(fromDateFilter).toLocal()}
                    onChange={(value) => {
                      value && setFromDateFilter(value.toString());
                    }}
                    onAccept={(value) => {
                      setExpandedFrom(!expandedFrom);
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color={'#303436BF'} pb={0.5}>
              To
            </Typography>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DesktopDatePicker
                value={DateTime.fromISO(toDateFilter).toLocal()}
                onChange={(value) => {
                  value && setToDateFilter(value.toString());
                }}
                onOpen={() => {
                  setExpandedTo(!expandedTo);
                }}
                slotProps={{
                  textField: { fullWidth: true }
                }}
              />
              <Accordion
                sx={{ boxShadow: 'none', m: '0 !important' }}
                expanded={expandedTo}
              >
                <AccordionSummary
                  sx={{
                    minHeight: '0 !important'
                  }}
                ></AccordionSummary>
                <AccordionDetails sx={{ pt: 0, px: 0 }}>
                  <StaticDatePicker
                    defaultValue={DateTime.fromISO(toDateFilter).toLocal()}
                    onChange={(value) => {
                      value && setToDateFilter(value.toString());
                    }}
                    onAccept={(value) => {
                      setExpandedTo(!expandedTo);
                    }}
                    maxDate={DateTime.now().toLocal()}
                  />
                </AccordionDetails>
              </Accordion>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface IProps {
  name: string;
  control: any;
  label?: string;
  placeholder?: string;
  rules?: any;
  startIcon?: React.ReactNode;
  style?: Object;
  btnLabel?: string;
  btnClick?: any;
}

export default function DateInput({
  name,
  label,
  placeholder,
  rules,
  control,
  startIcon,
  style,
  btnLabel,
  btnClick
}: IProps) {
  const isMobile = useIsMobile();
  const [showError, setShowError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isEndrollmentOpen, setIsEndrollmentOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [fromDateFilter, setFromDateFilter] = useState<string>('');
  const [toDateFilter, setToDateFilter] = useState<string>(
    DateTime.now().toString()
  );

  const autoRef = useRef(null);
  const [width, setWidth] = useState(0);

  const getDateRange = () => {
    const dateNow = DateTime.now().setLocale('en-us');
    return [
      {
        range: 'Past 7 days',
        dateVal: `${dateNow.minus({ days: 7 }).toLocaleString({
          day: '2-digit',
          month: 'short'
        })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })}`,
        from: dateNow.minus({ days: 7 }),
        to: dateNow
      },
      {
        range: 'Past 14 days',
        dateVal: `${dateNow.minus({ days: 14 }).toLocaleString({
          day: '2-digit',
          month: 'short'
        })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })}`,
        from: dateNow.minus({ days: 14 }),
        to: dateNow
      },
      {
        range: 'Past 30 days',
        dateVal: `${dateNow.minus({ days: 30 }).toLocaleString({
          day: '2-digit',
          month: 'short'
        })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })}`,
        from: dateNow.minus({ days: 30 }),
        to: dateNow
      },
      {
        range: 'Past 90 days',
        dateVal: `${dateNow.minus({ days: 90 }).toLocaleString({
          day: '2-digit',
          month: 'short'
        })} - ${dateNow.toLocaleString({
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })}`,
        from: dateNow.minus({ days: 90 }),
        to: dateNow
      }
    ];
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    const item = getDateRange().at(index);
    if (item) {
      setFromDateFilter(item?.from.toString());
      setToDateFilter(item?.to.toString());
      // setIsEndrollmentOpen(!isEndrollmentOpen);
    }
  };

  const getDate = () => {
    if (selectedIndex > -1) {
      return getDateRange().at(selectedIndex)?.range;
    }

    const dateNow = DateTime.now().setLocale('en-us');
    if (fromDateFilter) {
      return `${DateTime.fromISO(fromDateFilter).toLocaleString({
        day: '2-digit',
        month: 'short'
      })} - ${dateNow.toLocaleString({
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })}`;
    }
    return '';
  };

  let formOnChange: any = null;
  const onSubmit = () => {
    formOnChange({
      from: fromDateFilter,
      to: toDateFilter
    });
  };

  useLayoutEffect(() => {
    const current = autoRef?.current as any;
    setWidth(current?.offsetWidth);
  }, []);

  useEffect(() => {
    onSubmit();
  }, [fromDateFilter, toDateFilter]);

  return (
    <Box py={1}>
      {!isMobile && (
        <InputLabel htmlFor={name} sx={{ pb: '8px' }}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={getDate()}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          formOnChange = onChange;

          if (isMobile) {
            return (
              <DateInAccordion
                label={label ?? ''}
                fromDateFilter={fromDateFilter}
                setFromDateFilter={setFromDateFilter}
                toDateFilter={toDateFilter}
                setToDateFilter={setToDateFilter}
                setSelectedIndex={setSelectedIndex}
              />
            );
          }
          return (
            <TextField
              ref={autoRef}
              fullWidth
              style={style}
              variant="outlined"
              placeholder={placeholder}
              error={showError && value?.length !== 0 && !!error}
              helperText={
                showError && value?.length !== 0 && error ? error.message : null
              }
              sx={{}}
              InputLabelProps={{
                sx: {}
              }}
              InputProps={{
                sx: {
                  height: '48px',
                  borderRadius: '8px',
                  bgcolor: value?.from?.length > 0 ? '#4eaa76' : null,
                  color: value?.from?.length > 0 ? '#FFF' : null,
                  border:
                    '1px solid var(--dark-disabled, rgba(48, 52, 54, 0.14))'
                },
                startAdornment: startIcon ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: value?.from?.length > 0 ? '#FFF' : null
                    }}
                  >
                    {startIcon}
                  </InputAdornment>
                ) : null
              }}
              value={getDate()}
              onClick={(event: any) => {
                setIsEndrollmentOpen(!isEndrollmentOpen);
                setAnchorEl(event.currentTarget);
              }}
            />
          );
        }}
      />

      <Popper
        open={isEndrollmentOpen}
        anchorEl={anchorEl}
        placement={isMobile ? 'bottom' : 'bottom-start'}
        modifiers={[{ name: 'flip', enabled: false }]}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setIsEndrollmentOpen(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{ bgcolor: '#FFF' }}
                style={{
                  marginTop: 12,
                  borderRadius: '8px',
                  border: '1px solid #D9E2EC',
                  width: isMobile ? width : width + 200,
                  height: '454px'
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    fontSize={'12px'}
                    color={'#627D98'}
                    sx={{ pt: 2, px: 3, pb: 1 }}
                  >
                    DATE RANGE
                  </Typography>
                  <List sx={{ px: 1 }}>
                    {getDateRange().map((range, key) => (
                      <ListItemButton
                        key={key}
                        selected={selectedIndex === key}
                        onClick={(event) => handleListItemClick(event, key)}
                      >
                        <ListItemText
                          primary={
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Box>
                                <Typography>{range.range}</Typography>
                              </Box>
                              <Box>
                                <Typography>{range.dateVal}</Typography>
                              </Box>
                            </Box>
                          }
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
                <Divider sx={{ pt: 2 }} />
                <Box>
                  <Typography
                    variant="body2"
                    fontSize={'12px'}
                    color={'#627D98'}
                    sx={{ pt: 2, px: 3 }}
                  >
                    CUSTOM RANGE
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      px: 2,
                      pt: 2
                    }}
                  >
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          value={DateTime.fromISO(fromDateFilter).toLocal()}
                          onChange={(value) => {
                            value && setFromDateFilter(value.toString());
                            setSelectedIndex(-1);
                            // setIsEndrollmentOpen(!isEndrollmentOpen);
                          }}
                          slotProps={{
                            field: {
                              clearable: true,
                              onClear: () => {
                                setSelectedIndex(-1);
                                setFromDateFilter('');
                                // setIsEndrollmentOpen(!isEndrollmentOpen);
                              }
                            },
                            textField: {
                              placeholder: ''
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box px={1} pt={2}>
                      -
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          value={DateTime.fromISO(toDateFilter).toLocal()}
                          onChange={(value) => {
                            value && setToDateFilter(value.toString());
                            setSelectedIndex(-1);
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
                {btnLabel && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      py: 3,
                      px: 2
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      onClick={() => {
                        setIsEndrollmentOpen(!isEndrollmentOpen);
                        btnClick && btnClick();
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        color={'#FFF'}
                        textTransform={'none'}
                      >
                        {btnLabel}
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
}
