import {
  Box,
  Chip,
  InputBase,
  Select as MuiSelect,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ButtonCover } from "@ui/button";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import palette from "@utils/palette";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    padding: ".6875rem 1.25rem",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
    border: "1px solid",
    borderColor: palette.grey[50],
    borderRadius: ".5rem",
  },
  "& .MuiSelect-select.MuiInputBase-input": {
    padding: ".6875rem 1.25rem !important",
  },
}));

export const Select = ({ children, ...props }: any) => {
  return (
    <MuiSelect sx={{
      '&.MuiInputBase-root .MuiSelect-select': {
        padding: ".6875rem 1.25rem",
      }
    }} input={<BootstrapInput placeholder="dshj" />} {...props}>
      {children}
    </MuiSelect>
  );
};

export const SelectChip = ({ deleteBtn, onDelete, label, ...props }: any) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      {...props}
      sx={{
        background: palette.background.dark[20],
        borderRadius: "0.375rem",
        p: 6 / 8,
        pl: 12 / 8,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 600 }}>{label}</Typography>
      <ButtonCover
        onMouseDown={(event: any) => {
          event.stopPropagation();
        }}
        onClick={onDelete}
      >
        <CloseRoundedIcon
          onClick={onDelete}
          sx={{
            ml: 6 / 8,
            color: "#27293759",
            height: "1.25rem",
            width: "1.25rem",
          }}
        />
      </ButtonCover>
    </Stack>
  );
};

const CustomSelectInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4
  },
  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
    borderRadius: '.5rem'
  },
  '& .MuiSvgIcon-root': {
    height: '2rem',
    width: '2rem',
    marginLeft: '.5rem'
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    padding: '.25rem 2.5rem .25rem 0 !important'
  },
  '&.MuiInputBase-root .MuiSvgIcon-root': {
    width: '1.5rem',
    height: '1.5rem',
    color: '#5A6A72'
  }
}));

export const CustomSelect = ({ children, ...props }: any) => {
  return (
    <Select
      input={props.input || <CustomSelectInput />}
      {...props}
      IconComponent={ExpandMoreRoundedIcon}
    >
      {children}
    </Select>
  );
};



const FilterInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: '1.5rem',
    padding: ".6781rem .75rem",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
    border: "1px solid",
    borderColor: palette.grey[50],
    borderRadius: "1.5rem",
  },
}));

export const SortSelect = ({ children, ...props }: any) => {
  return (
    <MuiSelect
      input={<FilterInput />}
      {...props}
      IconComponent={ExpandMoreRoundedIcon}
    >
      {children}
    </MuiSelect>
  );
};
