import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Container, Grid, Paper } from '@mui/material';
import { theme } from '@utils/theme';
import { ReactNode } from 'react';
import { ReactComponent as Logo } from '@assets/images/GetDone.svg';

interface IProps {
  pageTitle: string;
  children: ReactNode;
}

export default function AuthLayout({ pageTitle, children }: IProps) {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        minHeight: '100vh',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.light
      }}
    >
      <Box pb={4}>
        <Logo />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: isMobile ? '90vw' : '100vw'
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
