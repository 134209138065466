import { Box, Typography } from '@mui/material';
import { theme } from '@utils/theme';

interface IProps {
  text: string;
  onClick: () => void;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2';
}

export default function Link({ text, onClick, variant }: IProps) {
  return (
    <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
      <Typography
        variant={variant ? variant : 'h4'}
        color={theme.palette.primary.main}
        sx={{ borderBottom: '1px solid #57BD8333' }}
      >
        {text}
      </Typography>
    </Box>
  );
}
