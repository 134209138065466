import { useIsMobile } from "@hooks/useIsMobile";
import { Box, Drawer, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/CloseRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';
import { useEffect, useState } from "react";
import PrimaryButton from "@components/buttons/PrimaryButton";
import { useAuth } from "@contexts/AuthContext";
import { putRequest } from "@utils/http.service";
import toast from "react-hot-toast";

export default function EditTechModal({wo, onClose, options}: any) {

    const isMobile = useIsMobile();

    const [selectedOption, setSelectedOption] = useState<any>({});

    const {user} = useAuth();

    useEffect(() => {
        if(wo.assignedTo) {
            const sP = options.find((op: any) => op.name === wo.assignedTo);
            setSelectedOption(sP);
        }
    }, []);

    const onSave = async () => {
        const update = {
            assignedTo: selectedOption.name, 
            assignedToPersonId: selectedOption.thirdPartyId
        }
        await putRequest(`work-orders/${wo.id}`, update, {
            headers: {
                Authorization: `Bearer ${user!!.token}`
            }
        });
        toast.success(`Update work order ${wo.id} successfully.`);
        onClose();
    }

    return <>
        <Drawer
            ModalProps={{
                keepMounted: true
            }}
            anchor={'bottom'}
            sx={{
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: isMobile ? '100%' : '42%',
                    minWidth: '300px',
                    // top: 'unset',
                    boxSizing: 'border-box',
                    border: 'none',
                    // backgroundColor: palette.primary[10],
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24
                },
                '&.MuiModal-root.MuiDrawer-root': {
                    zIndex: 1301
                }
            }}
            open={true}
            onClose={onClose}
        >
            <Box>
                <Box sx={{display: 'flex', flexDirection: 'row', margin: 2, justifyContent: 'space-between', borderBottom: 'solid 1px #E6ECEF'}}>
                    <Typography sx={{fontSize: '20px', fontWeight: '600', lineHeight: '28px'}}>Select Technician</Typography>
                    <Box sx={{cursor: 'pointer'}} onClick={onClose}><CloseIcon sx={{fontSize: '2rem'}}/></Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', margin: 2, gap: 2}}>
                    {options.map((op: any) => <>
                        <Box sx={{color: op.color, 
                                padding: '12px', 
                                border: 'solid ' + (selectedOption && selectedOption.name === op.name ? '2px #217345' : '1px rgba(48, 52, 54, 0.35)'), 
                                borderRadius: '8px', 
                                background: (selectedOption && selectedOption.name === op.name ? '#D6F9E5' : '#fff')
                            }} onClick={() => {
                            setSelectedOption(op);
                        }}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
                                        {op.icon}
                                        <Typography sx={{fontSize: '16px', fontWeight: selectedOption && selectedOption.name === op.name ? '500' : '400'}}>{op.name}</Typography>
                                    </Box>
                                    {selectedOption && selectedOption.name === op.name && <CheckIcon/>}
                                </Box>
                            </Box>
                        </Box>
                    </>)}
                </Box>
                <Box sx={{ margin: 2, marginTop: 3, marginBottom: 3 }}>
                    <PrimaryButton btnText="Save Changes" onClick={onSave}></PrimaryButton>
                </Box>
            </Box>
        </Drawer>
    </>

}