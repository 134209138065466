import PageLayout from '@components/layouts/PageLayout';
import DashboardPage from '@pages/DashboardPage';
import { ReactNode, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AuthRouter from './AuthRouter';
import { useAuth } from '@contexts/AuthContext';
import OpenWorkOrderPage from '@pages/WorkOrderPage';
import { AUTH_ROUTES, ROOT_ROUTES } from '@utils/routes';
import SettingsPage from '@pages/SettingsPage';
import ManageUsersPage from '@pages/ManageUsersPage';
import NotificationsPage from '@pages/NotificationsPage';
import TechDashboardPage from '@pages/tech/TechDashboardPage';
import TechMyProfilePage from '@pages/tech/TechMyProfilePage';
import TechProfilePage from '@pages/TechProfilePage';
import MakeReady from '@pages/MakeReadyPage';
import ManagePropertiesPage from '@pages/ManagePropertiesPage';

interface IProps {
  redirectPath: string;
  isAllowed: boolean;
  children: ReactNode;
}

export default function Router() {
  const { isAuthenticated, setUser, isPmRole, isAdminRole, updateTechList, user } = useAuth();

  const ProtectedRoute = ({ isAllowed, redirectPath, children }: IProps) => {
    // console.log(isAllowed, window.location.pathname, redirectPath);
    if (!isAllowed) {
      // redirect back to the path once user is available from app
      if (!window.location.pathname.includes('/auth')) {
        localStorage.setItem('redirectPath', window.location.pathname);
      }

      return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
  };

  useEffect(() => {
    (async() => {
      const loggedInUser = localStorage.getItem('user');
      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        setUser({ ...foundUser, selectedProperty: foundUser?.selectedProperty ? foundUser?.selectedProperty : foundUser?.properties?.[0] || null });
      }
    })();
  }, []);

  useEffect(() => {
    (async() => {
      if (user?.token) {
        await updateTechList(user?.selectedProperty ? user?.selectedProperty : user?.properties?.[0]);
      }
    })();
  }, [user?.token])

  return (
    <Routes>
      <Route
        element={
          // @ts-ignore
          <ProtectedRoute
            redirectPath={localStorage.getItem('redirectPath') ?? ROOT_ROUTES.HOME}
            isAllowed={!isAuthenticated()}
          />
        }
      >
        <Route path="/auth/*" element={<AuthRouter />} />
      </Route>
      <Route
        element={
          // @ts-ignore
          <ProtectedRoute
            redirectPath={AUTH_ROUTES.LOGIN}
            isAllowed={isAuthenticated()}
          />
        }
      >
        <Route
          path={ROOT_ROUTES.WORK_ORDERS}
          element={
            <PageLayout>
              <OpenWorkOrderPage />
            </PageLayout>
          }
        />
        <Route
          path={ROOT_ROUTES.NOTIFICATIONS}
          element={
            <PageLayout>
              <NotificationsPage />
            </PageLayout>
          }
        />
        {isPmRole() && <Route
          path={ROOT_ROUTES.MANAGE_USERS}
          element={
            <PageLayout>
              <ManageUsersPage />
            </PageLayout>
          }
        />}
        {isAdminRole() && <Route
          path={ROOT_ROUTES.MANAGE_PROPERTIES}
          element={
            <PageLayout>
              <ManagePropertiesPage />
            </PageLayout>
          }
        />}
        <Route
          path={ROOT_ROUTES.PROFILE}
          element={
            <PageLayout>
              {isPmRole() && <TechProfilePage />}
              {!isPmRole() && <TechMyProfilePage />}
            </PageLayout>
          }
        />
        <Route
          path={ROOT_ROUTES.PROFILE_ID}
          element={
            <PageLayout>
              <TechProfilePage />
            </PageLayout>
          }
        />
        <Route
          path={ROOT_ROUTES.SETTINGS}
          element={
            <PageLayout>
              <SettingsPage />
            </PageLayout>
          }
        />
        <Route
          path={ROOT_ROUTES.HOME + '/*'}
          element={
            <PageLayout>
              {isPmRole() && <DashboardPage />}
              {!isPmRole() && <TechDashboardPage />}
            </PageLayout>
          }
        />
        <Route path={ROOT_ROUTES.MAKE_READY} element={<PageLayout><MakeReady /></PageLayout>}></Route>
      </Route>
    </Routes>
  );
}
