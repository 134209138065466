import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import { useIsMobile, useIsTab } from "@hooks/useIsMobile";
import { useEffect, useMemo, useState } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);


export default function WorkOrderBreakDownChart({graphsData}: any) {
    const isMobile = useIsMobile();
    const isTab = useIsTab();
    const theme = useTheme();


    const [data, setData] = useState<any>({});
    const [rows, setRows] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    const colors =['#F9703E',
    '#FF9466',
    '#FFB088',
    '#FFD0B5'];

    useEffect(() => {
      if(graphsData && graphsData.wosByTech) {
        let respArr = [];
        let total = 0;
        for(let k of Object.keys(graphsData.wosByTech)){ 
          respArr.push({key: k, val: graphsData.wosByTech[k].length});
          total += graphsData.wosByTech[k].length;
        };
        setTotal(total);
        respArr = respArr.sort((a: any, b: any) => b.val - a.val);
        setRows(respArr.slice(0, 4));
        setData({
          labels: respArr.slice(0, 4).map((r) => r.key) ,
          datasets: [
            {
              data: respArr.slice(0, 4).map((r) => r.val),
              backgroundColor: colors,
            }
          ]
        });
      }
    }, [graphsData]);

    const options: ChartOptions<"pie"> = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                mode: 'index',
                intersect: false,
                external: function(context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');
    
                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }
                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }
                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }
                    function getBody(bodyItem: any) {
                        return bodyItem.lines;
                    }
                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);
                        let innerHtml = '<tbody>';
                        bodyLines.forEach(function(body, i) {
                            const colors = tooltipModel.labelColors[i];
                            let style = 'background: #fff';
                            style += '; display: flex';
                            style += '; flex-direction: column';
                            style += '; padding: 4px 8px';
                            style += '; justify-content: center';
                            style += '; align-items: center';
                            style += '; border: 1px solid var(--dark-disabled, rgba(48, 52, 54, 0.14))';
                            style += '; border-radius: 5px';
                            style += '; font-size: 14px';
                            style += '; box-shadow: 0px 2px 8px 0px rgba(48, 52, 54, 0.08)'
                            const span = '<span style="' + style + '"><span>' + titleLines[0] + '</span><span>' +  body + '</span></span>';
                            innerHtml += '<tr><td>' + span + '</td></tr>';
                        });
                        innerHtml += '</tbody>';
                        let tableRoot = tooltipEl.querySelector('table');
                        tableRoot!!.innerHTML = innerHtml;
                    }
                    const position = context.chart.canvas.getBoundingClientRect();
                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 20 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 50 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
        },
        radius: 100,
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }

    const LegendItem = ({ label, description, color }: { label: string, description: string, color: string }) => {
        return <Stack direction="row">
            <Box>
                <Box sx={{
                    width: '.5rem',
                    height: '1rem',
                    backgroundColor: color,
                    borderRadius: '1.5rem',
                    mr: 1,
                    mt: .5
                }}></Box>
            </Box>
            <Box sx={{ flex: 1 }}>
                <Typography variant="body1" sx={{ mb: .5 }}>{label}</Typography>
                <Typography variant="body2" sx={{ color: '#5A6A72', fontWeight: 400 }}>{description}</Typography>
            </Box>
        </Stack>
    }

    const getLegends = () => {
        return <Grid container spacing={3} sx={{ ml: isTab ? 0 : 22 / 8 }} >
            {rows && rows.length && rows.map((row, index) => (
                <Grid item xs={isTab ? 6 : 12}>
                    <LegendItem label={row.key} color={colors[index]} description={row.val + ' Work Orders'} />
                </Grid>
            ))}
    </Grid>
    };

    return <>
        {
            isTab ?
                <Stack>
                    <Stack sx={{ height: '14rem' }} alignItems="center">
                        {data && data.labels && <Pie data={data} options={options} />}
                    </Stack>
                    <Box sx={{ mt: 2 }}>
                        {getLegends()}
                    </Box>
                </Stack>
                : <Grid container>
                    <Grid item xs={5} sx={{
                        borderRight: '1px solid rgba(48, 52, 54, 0.14)'
                    }}>
                        {getLegends()}
                    </Grid>
                    <Grid item xs={7}>
                        <Stack justifyContent="center" alignItems="center">
                            {data && data.labels && <Pie data={data} options={options} />}
                        </Stack>
                    </Grid>
                </Grid>
        }

    </>
}