import PrimaryButton from '@components/buttons/PrimaryButton';
import PropertySelector from '@components/inputs/PropertySelect';
import PageHeader from '@components/PageHeader';
import {
  Box,
  Button,
  Chip,
  DialogContent,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddEditUser from '@components/manage-users/add-edit-user/add-edit-user';
import { getColorByText, getInitials, getRole } from '@utils/helpers';
import { useIsMobile } from '@hooks/useIsMobile';
import { ButtonCover } from '@ui/button';
import { PopoverActionListItem, Popover } from '@ui/popover';
import { Dialog } from '@ui/dialog';
import palette from '@utils/palette';
import { useAuth } from '@contexts/AuthContext';
import { MobileSelect } from './DashboardPage';
import SelectDrawer from '@components/drawers/SelectDrawer';
import { getRequest, postRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';


const Table = styled(MuiTable)(({theme})=>({
}))

enum DialogType {
  AddEdit = 'AddEdit',
  Remove = 'Remove'
}

export default function ManageUsersPage() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedUser, setSelectedUser] = useState(null as any);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isMobile = useIsMobile();
  const [dialog, setDialog] = useState<{
    open: boolean,
    type: DialogType | null,
    user?: any
  }>({
    open: true,
    type: null,
    user: null
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });
  const renderNameTag = (name: string) => {
    return (
      <Box
        sx={{
          width: isMobile ? '42px' : '32px',
          height: isMobile ? '42px' : '32px',
          lineHeight: isMobile ? '42px' : '32px',
          border: '1px solid #00000014',
          borderRadius: '50%',
          textAlign: 'center',
          background: getColorByText(name),
          color: '#fff'
        }}
      >
        {getInitials(name)}
      </Box>
    );
  };

  const [rows, setRows] = useState<any[]>([]);
  const {user, updateTechList} = useAuth();

  useEffect(() => {
    getUsers();
  },[]);

  const getUsers = async() => {
    const usersResp = await getRequest(`users?propertyId=${user!!.selectedProperty.id}`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    setRows(usersResp.data.filter((u: any) => u.roles[0].role !== 'admin'));
  }

  enum DrawerType {
    DateFilter = 'DateFilter',
    PropertyFilter = 'PropertyFilter'
  }

  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });

  return (
    <>
      <Box>
        <PageHeader
          title={<FormattedMessage id="Manage Users" />}
          selection={(user?.properties?.length || 0) > 1 && <PropertySelector />}
          // action={
          //   <PrimaryButton
          //     onClick={() => setDialog({
          //       open: true,
          //       type: DialogType.AddEdit,
          //       user: null
          //     })}
          //     btnText={<FormattedMessage id="Add User"/>}
          //     isSmall
          //     startIcon={<AddCircleOutlineIcon />}
          //     style={{ p: '8px 8px' }}
          //   />
          // }
        />
        {isMobile ? (
          <Stack direction="row" spacing={2} sx={{ backgroundColor: palette.white, mx: -2, px: 2, mt: -4, pt: 4, mb: -2, pb: 2 }}>
            <Box onClick={() => {
              setDrawer({
                open: true,
                type: DrawerType.PropertyFilter
              })
            }}>
              { (user?.properties?.length || 0) > 1 ? <PropertySelector
                sx={{
                  pointerEvents: 'none'
                }} input={<MobileSelect />}
                renderValue={(displayValue) => (value: any) => displayValue}
              /> : '' }
            </Box>
          </Stack>
        ) : null}
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 'rgba(48, 52, 54, 0.1) 0px 6px 24px 0px',
            borderRadius: isMobile ? 'none' : '1rem',
            mt: 5,
            mb: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {isMobile && (
                  <TableCell
                    sx={{
                      borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                      color: '#8D9DA5'
                    }}
                  >
                    <Typography variant="body1" color={'#8D9DA5'}>
                      Users
                    </Typography>
                  </TableCell>
                )}
                {!isMobile && (
                  <>
                    <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                        color: '#8D9DA5'
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                        color: '#8D9DA5'
                      }}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                        color: '#8D9DA5'
                      }}
                    >
                      Email
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3',
                        color: '#8D9DA5'
                      }}
                    >
                      Property
                    </TableCell> */}
                    {/* <TableCell
                      sx={{
                        borderBottom: isMobile ? 'none' : '1px solid #EDF1F3'
                      }}
                    ></TableCell> */}
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice((paginationModel.page * paginationModel.pageSize), (paginationModel.page * paginationModel.pageSize + paginationModel.pageSize)).map((row, index) => (
                <TableRow key={index}>
                  {isMobile && (
                    <TableCell
                      scope="row"
                      sx={{
                        borderBottom: '1px solid #EDF1F3'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          {renderNameTag(row.firstName + ' ' + row.lastName)}
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                                gap: 1
                              }}
                            >
                              <Typography color={'#5A6A72'}>
                                {row.firstName + ' ' + row.lastName}
                              </Typography>
                              <Box
                                sx={{
                                  bgcolor: '#5A6A72',
                                  height: '4px',
                                  width: '4px',
                                  borderRadius: '50%'
                                }}
                              ></Box>
                              <Typography color={'#5A6A72'}>
                                {getRole(row)}
                              </Typography>
                            </Box>
                            <Typography>{row.username}</Typography>
                          </Box>
                        </Box>
                        {/* <Box
                          sx={{ cursor: 'pointer', pr: isMobile ? 0 : 2 }}
                          onClick={() => setDialog({ open: true, type: DialogType.AddEdit, user: row })}
                        >
                          <Typography variant="body1" color={'#57BD83'}>
                            Edit
                          </Typography>
                        </Box> */}
                      </Box>
                    </TableCell>
                  )}
                  {!isMobile && (
                    <>
                      <TableCell
                        scope="row"
                        sx={{
                          borderBottom: '1px solid #EDF1F3'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            marginBottom: '1rem',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          {renderNameTag(row.firstName + ' ' + row.lastName)}
                          <Typography>{row.firstName + ' ' + row.lastName}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Chip
                            label={getRole(row)}
                            sx={{ px: 1.5, py: 0.5, '& span': { p: 0 } }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '1rem' }}>{row.username}</TableCell>
                      {/* <TableCell sx={{ fontSize: '1rem' }}>{row.property}</TableCell> */}
                      {/* <TableCell>
                        <ButtonCover onClick={(e) => {
                          setSelectedUser(row);
                          handleClick(e);
                        }}>
                          <MoreHorizIcon />
                        </ButtonCover>
                      </TableCell> */}
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 5
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography pr={2} pt={1}>
                <FormattedMessage id="Rows Per Page" />
              </Typography>
              <TextField
                select
                label=""
                defaultValue="10"
                size="small"
                onChange={(e) => {
                  setPaginationModel({
                    page: 0,
                    pageSize: parseInt(e.target.value, 10)
                  });
                }}
                sx={{ bgcolor: '#E8EBEE' }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </TextField>
            </Box>
            <Pagination
              page={paginationModel.page + 1}
              count={Math.ceil(rows.length / paginationModel.pageSize)}
              siblingCount={0}
              shape="rounded"
              onChange={(e, page) =>
                setPaginationModel({
                  page: page - 1,
                  pageSize: paginationModel.pageSize
                })
              }
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: NavigateBeforeIcon,
                    next: NavigateNextIcon
                  }}
                  {...item}
                />
              )}
            />
          </Box>
        </Grid>
        {/* {isMobile && (
          <Grid item xs={12}>
            <PrimaryButton btnText="Create New User" isFullWidth onClick={() => setDialog({
              open: true,
              type: DialogType.AddEdit
            })} />
          </Grid>
        )} */}
      </Box>
      {dialog.open && dialog.type == DialogType.AddEdit && <AddEditUser
        open={dialog.open && dialog.type == DialogType.AddEdit}
        user={dialog.user}
        isEditMode={dialog.user}
        handleClose={(refresh) => {
          setDialog({
            open: false,
            type: null
          });
          if(refresh) {
            getUsers();
          }
        }}
        onRemove={() => {
          setDialog({
            open: true,
            type: DialogType.Remove
          })
        }}
      />}
      <Dialog
        size="sm"
        open={dialog.open && dialog.type == DialogType.Remove}
        onClose={(ev, reason) => {
          if (reason && reason === "backdropClick") {
            return;
          }
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            "& .MuiDialogContent-root": {
              padding: 5,
            },
          }}
        >
          <Typography sx={{
            fontSize: '2.5rem',
            lineHeight: 1.1,
            fontWeight: 500,
            color: '#212121',
            mb: 3
          }}>Remove User</Typography>
          <Typography variant="h5" sx={{ fontWeight: 400, mb: 5 }}>Are you sure you want to remove
            &nbsp;<Typography component="span" variant="h5" sx={{ fontWeight: 600 }}>{dialog?.user?.name}</Typography> ?
          </Typography>

          <Stack direction="row" spacing={3} sx={{
            '& button': {
              px: 2.5, py: 14 / 8,
              borderRadius: '.5rem',
            }
          }}>
            <Button onClick={() => setDialog({
              open: false,
              type: null,
              user: null
            })} fullWidth variant="outlined" sx={{ border: '1px solid rgba(48, 52, 54, 0.35)' }}>Return</Button>
            <Button fullWidth sx={{
              backgroundColor: '#CF1124', color: palette.white, '&:hover': {
                backgroundColor: '#CF1124'
              }
            }} onClick={async() => {
              await postRequest(`users/remove`, {id: dialog.user.id, propertyId: user?.selectedProperty.id});
              setDialog({
                open: false,
                type: null,
                user: null
              });
              getUsers();
            }}>Remove</Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <PopoverActionListItem onClick={() => {
          handleClose(); setDialog({ open: true, type: DialogType.AddEdit, user: selectedUser })
        }}>
          <Typography variant="body1" sx={{ color: '3303436' }}>Edit</Typography>
        </PopoverActionListItem>
        <PopoverActionListItem onClick={() => {
          handleClose(); setDialog({ open: true, type: DialogType.Remove, user: selectedUser })
        }}>
          <Typography variant="body1" sx={{ color: '#CF1124' }}>Remove</Typography>
        </PopoverActionListItem>
      </Popover>

      {
        isMobile ?
          <>
            <SelectDrawer
              open={drawer.open && drawer.type === DrawerType.PropertyFilter}
              onClose={() => {
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              options={(user || [] as any).properties.map((p: any) => ({ label: p.name, value: p.id }))}
              onChange={(value) => {
                const sp = (user?.properties || []).find((p) => p.id == (value));
                updateTechList(sp);
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              name="property-filter"
              selected={user!!.selectedProperty.id}
              submitBtnLabel='View Property'
              heading="Select Property to View"
            />
          </> : ''
      }
    </>
  );
}
