import { Box, Grid, MenuItem as MuiMenuItem, Typography, MenuItem, ListItemText, Stack, Button, Radio, FormControlLabel, Checkbox } from "@mui/material";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import { FormInputField, FormSelectField } from "@ui/form";
import PrimaryButton from "@components/buttons/PrimaryButton";
import { SelectChip } from "@ui/select";
import palette from "@utils/palette";
import BasicDatePicker from "@ui/dateInput";
import BasicTimePicker from "@ui/time-picker";
import { DateTime } from "luxon";
import { useAuth } from "@contexts/AuthContext";
import { CheckBox } from "@mui/icons-material";

interface ITechniciansAndStatus {
    control: Control<any>;
    errors: FieldErrors<any>;
    onNext: () => void;
    onBack: () => void;
    setValue: any;
    isValid: boolean;
}

const technicians = [{ name: 'John' }, { name: 'Shawn' }];
export default function Schedule({ control, errors, onNext, setValue, onBack, isValid }: ITechniciansAndStatus) {
    const {user} = useAuth();
    return <>
        <Stack sx={{ mt: 3, backgroundColor: palette.white, px: 3, py: 20 / 8 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, mb: 3, color: '#5A6A72' }}>Technicians & Status</Typography>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Controller
                            name="technicians"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FormSelectField
                                        {...field}
                                        label="Select techincian(s) (Optional)"
                                        multiple
                                        renderValue={(selected: any) => {
                                            return (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        gap: 6 / 8,
                                                    }}
                                                >
                                                    {selected.map((value: any, index: number) => {
                                                        const prop = user?.techUsers.find((pro) => pro.name == value);
                                                        return <SelectChip
                                                            key={value}
                                                            label={prop?.name}
                                                            onDelete={() => {
                                                                const copy = [...selected];
                                                                copy.splice(index, 1);
                                                                setValue("technicians", copy);
                                                            }}
                                                        />
                                                    })}
                                                </Box>
                                            );
                                        }}
                                    >
                                        {(user?.techUsers || []).map((tech) => (
                                            <MenuItem key={tech.name} value={tech.name}>
                                                <ListItemText primary={tech.name} />
                                            </MenuItem>
                                        ))}
                                    </FormSelectField>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="workOrderStatus"
                        control={control}
                        rules={{
                            required: "Work Order Status is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Select the status of Work Order"
                                    {...field}
                                    error={errors?.category}
                                    helperText={errors?.category?.message}
                                >
                                    <MuiMenuItem value={"Submitted"}>Not Started</MuiMenuItem>
                                    <MuiMenuItem value={"Scheduled"}>Scheduled</MuiMenuItem>
                                    <MuiMenuItem value={"In Progress"}>In Progress</MuiMenuItem>
                                    <MuiMenuItem value={"On Hold"}>On Hold</MuiMenuItem>
                                    <MuiMenuItem value={"Completed"}>Completed</MuiMenuItem>
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </Stack>
        <Stack sx={{ mt: 3,  mb: 12, backgroundColor: palette.white, px: 3, py: 20 / 8 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, mb: 3, color: '#5A6A72' }}>Schedule</Typography>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Controller
                        name="appointmentDate"
                        control={control}
                        rules={{
                            required: "Appointment Date is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <BasicDatePicker value={DateTime.fromISO(field.value).toLocal()}
                                    onChange={(value: any) => {
                                        field.onChange(value.toString());
                                    }} label="Date" />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="reoccur"
                        control={control}
                        // rules={{
                        //     required: "Appointment Date is required.",
                        // }}
                        render={({ field }) => {
                            return (
                                <Stack direction="row" spacing={10/8}>
                                    <FormControlLabel
                                        label='Re-occur work order on the same day every week'
                                        control={
                                            <Checkbox onChange={field.onChange} />
                                        }
                                    />
                                    {/* <Typography variant="body2"></Typography> */}
                                </Stack>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <label>
                            <Typography variant="body1" sx={{ color: '#303436bf' }}>
                                Select Time Range (Optional)
                            </Typography>
                        </label>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Controller
                            name="appointmentStartTime"
                            control={control}
                            // rules={{
                            //     required: "Appointment Start Time is required.",
                            // }}
                            render={({ field }) => {
                                return (
                                    <BasicTimePicker value={field.value ? DateTime.fromISO(field.value).toLocal() : undefined}
                                        onChange={(value: any) => {
                                            field.onChange(value?.toString());
                                        }} />
                                );
                            }}
                        />
                        <Stack spacing={1} justifyContent="center" sx={{ pt: 1 }}>
                            <Box sx={{
                                height: '.25rem',
                                width: '1rem',
                                background: '#30343659',
                                borderRadius: '.25rem'
                            }}></Box>
                        </Stack>
                        <Controller
                            name="appointmentEndTime"
                            control={control}
                            // rules={{
                            //     required: "Appointment End Time is required.",
                            // }}
                            render={({ field }) => {
                                return (
                                    <BasicTimePicker value={field.value ? DateTime.fromISO(field.value).toLocal() : undefined}
                                        onChange={(value: any) => {
                                            field.onChange(value?.toString());
                                        }} />
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="notes"
                        control={control}
                        // rules={{
                        //     required: "Notes is required.",
                        // }}
                        render={({ field }) => {
                            return (
                                <FormInputField
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    placeholder={'Optional'}
                                    label="Notes"
                                    {...field}
                                    error={errors?.unitNumber}
                                    helperText={errors?.unitNumber?.message}
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
            
        </Stack>
        <Stack sx={{ backgroundColor: palette.white, px: 3, py: 20 / 8, marginTop: 'auto', position: 'fixed', bottom: 0, width: '100%', maxWidth: '432px' }}>
            <Stack direction="row" spacing={3} sx={{
                '& button': {
                    px: 2.5, py: 14 / 8,
                    borderRadius: '.5rem',
                }
            }}>
                <Button onClick={onBack} fullWidth variant="outlined" sx={{ border: '1px solid rgba(48, 52, 54, 0.35)' }}>Go Back</Button>
                <Button onClick={onNext} disabled={!isValid} fullWidth sx={{
                    backgroundColor: '#57BD83', color: palette.white, '&:hover': {
                        backgroundColor: '##57BD83'
                    }
                }}>Confirm</Button>
            </Stack>
        </Stack>
    </>
}