import AuthLayout from '@components/layouts/AuthLayout';
import { useIsMobile } from '@hooks/useIsMobile';
import { Alert, Box, ButtonBase, Grid, Paper, Stack, styled, Typography } from '@mui/material';
import { ReactComponent as GoogleLogo } from '@assets/images/google_logo.svg';
import { useAuth } from '@contexts/AuthContext';
import { useState } from 'react';
import { signInWithGoogle } from 'src/firebase';

interface IFormInput {
  email: string;
  password: string;
}

const SignInButton = styled(ButtonBase)(() => ({
  padding: '1rem 1.25rem',
  borderRadius: '.5rem',
  border: `1px solid #27293759`,
  width: '100%',
  maxWidth: '26rem',
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: '.625rem',
}));

export default function LoginPage() {
  const { login } = useAuth();
  const isMobile = useIsMobile();

  const [errorMsg, setErrorMsg] = useState('');

  const onClick = async () => {
    const res = await signInWithGoogle();
    const token = await res.user.getIdToken();
     await login(token, res.user?.email);
  };


  return (
    <AuthLayout pageTitle="Log In">
      <Paper
        sx={{
          width: isMobile ? '100%' : '39.5rem',
          px: isMobile ? '1rem' : '7rem',
          py: '1rem',
          borderRadius: '16px',
          boxShadow: '0px 10px 40px 2px #2173451F'
        }}
      >
        <Box py={4}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            mb="1rem"
            textAlign={'center'}
          >
            Welcome Back
          </Typography>
        </Box>
        <Grid item xs={6} sx={{ height: '100%', px: 4 }}>
          <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <SignInButton onClick={onClick}>
              <GoogleLogo /> Sign in with Google
            </SignInButton>
          </Stack>
        </Grid>
      </Paper>
      {errorMsg && <Alert severity='error' onClose={() => {setErrorMsg('')}}>{errorMsg}</Alert>}
    </AuthLayout>
  );
}
