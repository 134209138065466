import { Box } from '@mui/material';
import Sidebar from '../sidebar';
import { useIsMobile } from '@hooks/useIsMobile';

export default function PageLayout(props: any) {
  const isMobile = useIsMobile();

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          position: 'relative',
          pl: isMobile ? 0 : 100 / 8
        }}
      >
        <Sidebar />
        <Box sx={{ pt: 5, pb: isMobile ? 10 : 5, px: 2 }}>{props?.children}</Box>
      </Box>
    </>
  );
}
