import { Box, InputAdornment, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

interface IProps {
  name: string;
  label?: any;
  placeholder?: string;
  control: any;
  rules: any;
  type?: string;
  startIcon?: React.ReactNode;
  isSubmitFocus?: boolean;
  style?: Object;
  sx?: any;
  labelSx?: any;
  disabled?: boolean;
  onBlur?: any;
}

export default function TextInput({
  name,
  label,
  placeholder,
  rules,
  control,
  type,
  startIcon,
  isSubmitFocus,
  style,
  sx,
  labelSx,
  disabled,
  onBlur = () => {}
}: IProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const isPassword = type === 'password';

  return (
    <Box py={1}>
      <InputLabel htmlFor={name} sx={{ pb: '8px', ...labelSx }}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={''}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            disabled={disabled}
            fullWidth
            style={style}
            variant="outlined"
            placeholder={placeholder}
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            error={showError && value?.length !== 0 && !!error}
            helperText={
              showError && value?.length !== 0 && error ? error.message : null
            }
            sx={sx}
            InputLabelProps={{
              sx: {}
            }}
            InputProps={{
              sx: {
                height: '48px',
                borderRadius: '8px',
                border: '1px solid var(--dark-disabled, rgba(48, 52, 54, 0.14))'
              },
              startAdornment: startIcon ? (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ) : null
            }}
            value={value}
            onChange={onChange}
            onFocus={() => {
              if (
                isSubmitFocus &&
                document &&
                document.getElementById('sub_btn')
              ) {
                setTimeout(() => {
                  document.getElementById('sub_btn')?.scrollIntoView(true);
                }, 300);
              }
            }}
            onBlur={onBlur}
          />
        )}
      />
    </Box>
  );
}
