import PrimaryButton from '@components/buttons/PrimaryButton';
import TextInput from '@components/inputs/TextInput';
import AuthLayout from '@components/layouts/AuthLayout';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Paper, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HttpsIcon from '@mui/icons-material/Https';
import { useEffect, useState } from 'react';
import { postRequest } from '@utils/http.service';
import { jwtDecode } from "jwt-decode";
import { FullStory } from '@fullstory/browser';

interface IFormInput {
  password: string;
  password_confirm: string;
}

export default function PasswordResetPage() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { control, handleSubmit, formState, setError } = useForm<IFormInput>({
    defaultValues: { password_confirm: '', password: '' },
    mode: 'onChange'
  });

  const [token, setToken] = useState('');
  const [type, setType] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setToken(searchParams.get('token') ?? token);
    setType(searchParams.get('type') ?? type);
    if(searchParams.get('token') ?? token) {
      const decoded: any = jwtDecode(searchParams.get('token') ?? token);
      FullStory('setIdentity', {
        uid: decoded.sub,
        properties: {
          email: decoded.username
        }
      })
    }
    // setSearchParams({})
  }, [searchParams]);

  const onSubmit = async ({ password_confirm, password }: IFormInput) => {
    try {
      await postRequest(`auth/reset-password`, {
        password,
        token
      });
      navigate('/auth/login');
    } catch (err: any) {
      setError('password', {
        message: err.response.data.message
      });
    }
  };

  return (
    <AuthLayout pageTitle="Log In">
      <Paper
        sx={{
          width: isMobile ? '100%' : '39.5rem',
          px: isMobile ? '1rem' : '7rem',
          py: '1rem',
          borderRadius: '16px',
          boxShadow: '0px 10px 40px 2px #2173451F'
        }}
      >
        <Box py={4}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            mb="1rem"
            textAlign={'center'}
          >
            {type === 'invite' ? 'Create' : 'Reset'} Your Password
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '2rem' }}>
              <TextInput
                name="password"
                label="New Password"
                placeholder="Enter your new password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Password is required'
                  },
                  minLength: {
                    value: 6,
                    message: 'Password is too short'
                  }
                }}
                type="password"
                isSubmitFocus={true}
                startIcon={<HttpsIcon />}
              />
              <TextInput
                name="password_confirm"
                label="Confirm New Password"
                placeholder="Re-Enter New Password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Password is required'
                  },
                  minLength: {
                    value: 6,
                    message: 'Password is too short'
                  }
                }}
                type="password"
                isSubmitFocus={true}
                startIcon={<HttpsIcon />}
              />
            </Box>
            <Box textAlign="center">
              <PrimaryButton
                btnText="Update Password"
                formState={formState}
                isFullWidth
              />
            </Box>
          </form>
        </Box>
      </Paper>
    </AuthLayout>
  );
}
