export enum AUTH_ROUTES {
  LOGIN = '/auth/login',
  SIGNUP = '/auth/signup',
  RESET_PASSWORD = '/auth/reset-password',
  FORGOT_PASSWORD = '/auth/forgot-password'
}

export enum ROOT_ROUTES {
  HOME = '/',
  WORK_ORDERS = '/work-orders',
  NOTIFICATIONS = '/notifications',
  MANAGE_USERS = '/manage-users',
  MANAGE_PROPERTIES = '/manage-properties',
  SETTINGS = '/settings',
  PROFILE = '/profile',
  PROFILE_ID = '/profile/:id',
  MAKE_READY = '/make-ready'
}
