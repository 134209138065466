import {
  Box,
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography
} from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIsMobile } from '@hooks/useIsMobile';
import { useAuth } from '@contexts/AuthContext';
import { putRequest } from '@utils/http.service';
import toast from 'react-hot-toast';
import SelectDrawer from '@components/drawers/SelectDrawer';
import { DrawerType, getDrawerType } from '@utils/helpers';

interface IOptionProps {
  id: any;
  name: string;
  description?: string;
  color?: string;
  icon?: React.ReactNode;
  firstName?: string;
  lastName?: string;
  background?: string;
}

interface IEditProps {
  editParams: GridRenderEditCellParams;
  options: IOptionProps[];
  extraWidth?: number;
  onClose?: any;
}

export default function GridEditInput({
  editParams,
  options,
  extraWidth = 0,
  onClose
}: IEditProps) {
  const isMobile = useIsMobile();
  const { id, value, field, hasFocus, row } = editParams;
  const apiRef = useGridApiContext();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedValue, setSelectedValue] = useState<IOptionProps>();
  const { user } = useAuth();
  const [ optionsToShow, setOptionsToShow ] = useState([] as any[]);

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      const current = ref?.current as any;
      current.focus();
      setWidth(current.offsetWidth);
      setAnchorEl(ref.current);
    }
  }, [hasFocus]);

  useEffect(() => {
    setOptionsToShow(options);
    if(value) {
      const sO = options.find((o) => o.name === value);
      setSelectedValue(sO);
    }
    setDrawer({
      open: true,
      type: getDrawerType(field)
    })
  }, []);

  const onSelected = async(value: any) => {
    setSelectedValue(value);
    let isValid = await apiRef.current.setEditCellValue({
      id,
      field,
      value: value.name || value.firstName + ' ' + value.lastName
    });
    if(`${value.id}`.indexOf('cat') == 0) {
      isValid = false;
      setOptionsToShow(value.subCategories);
    } else {
      setDrawer({
        open: false,
        type: null
      })
    }
    if (isValid) {
      apiRef.current.stopCellEditMode({ id, field });

      if(field === 'assignedTo') {
        await putRequest(`work-orders/${row.other.id}`, {assignedTo: value.name || value.firstName + ' ' + value.lastName, assignedToPersonId: value.thirdPartyId}, {
          headers: {
            Authorization: `Bearer ${user!!.token}`
          }
        });
      }

      if(field === 'status') {
        await putRequest(`work-orders/${row.other.id}`, {status: value.name}, {
          headers: {
            Authorization: `Bearer ${user!!.token}`
          }
        });
      }

      if(field === 'priority') {
        await putRequest(`work-orders/${row.other.id}`, {priority: value.name}, {
          headers: {
            Authorization: `Bearer ${user!!.token}`
          }
        });
      }

      if(field === 'category') {
        await putRequest(`work-orders/${row.other.id}`, {category: value.name, categoryId: value.id}, {
          headers: {
            Authorization: `Bearer ${user!!.token}`
          }
        });
      }
      onClose && onClose();

      toast.success(`Update work order ${row.other.id} successfully.`);
    }
  };

  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });

  return (
    <>
      <TextField fullWidth ref={ref} type="text" value={value} disabled />
      {isMobile ? <>
        <SelectDrawer
          open={drawer.open}
          onClose={() => {
            setDrawer({
              open: false,
              type: null
            })
            apiRef.current.stopCellEditMode({ id, field });
            onClose && onClose();
          }}
          options={optionsToShow}
          onChange={(value) => {
            console.log(value);
            onSelected(optionsToShow.find((op) => op.name === value));
          }}
          name={field + '-value'}
          selected={selectedValue?.name ?? ''}
          submitBtnLabel='Save Changes'
          heading="Select value"
        />
      </> : 
        <Popper
          open={true}
          anchorEl={anchorEl}
          disablePortal={false}
          placement={isMobile ? 'top' : 'bottom-end'}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: 'document',
                padding: 8
              }
            }
          ]}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{ bgcolor: '#FFF' }}
                style={{
                  marginTop: 3,
                  borderBottomRightRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  border: '1px solid #D9E2EC',
                  minWidth: width + extraWidth,
                  width: 'fit-content',
                  maxHeight: '444px'
                }}
              >
                <Box
                  sx={{
                    maxHeight: '380px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: 2,
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {optionsToShow.map((opt: IOptionProps, i: number) => {
                    return (
                      <MenuItem
                        key={i}
                        value={opt.id}
                        onClick={(e) => onSelected(opt)}
                        sx={{ pl: 3, background: opt.background }}
                      >
                        {opt.icon && (
                          <ListItemIcon sx={{ color: opt.color }}>
                            {opt.icon}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography color={opt.color}>{opt.name}</Typography>
                          }
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      }
    </>
  );
}
