import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Box, IconButton, Stack, TextField, Typography, useForkRef } from '@mui/material';
import palette from '@utils/palette';
import { useDateField } from '@mui/x-date-pickers/DateField/useDateField';
import { useClearableField } from '@mui/x-date-pickers';


const textFieldStyles = {
    "& .MuiInputBase-root": {
        flexDirection: 'row-reverse'
    },
    "& .MuiInputBase-input": {
        padding: ".75rem 1.25rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: '#30343624',
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: '#30343624',
            borderRadius: ".5rem",
        },
        "&:not(.Mui-error):hover fieldset": {
            borderColor: '#30343624',
        },
        "&.Mui-focused:not(.Mui-error) fieldset": {
            borderColor: '#30343624',
        },
    },

    "& .MuiInputLabel-root": {
        transform: "translate(16px, 12px) scale(1)",
        "&.Mui-focused, &.MuiFormLabel-filled": {
            transform: "translate(16px, -9px) scale(0.75)",
        },
    },
    "& .MuiInputLabel-root:not(.Mui-error)": {
        color: palette.grey[40],
    },
    "& label": {
        fontSize: "1rem",
        lineHeight: 1.5,
        fontWeight: 400,
    },
    '& input': {
        borderRadius: ".5rem",
    },
    '& svg': {
        color: '#303436',
        opacity: .35
    }
};

export default function BasicDatePicker({ label, ...props }: any) {
    return (
        <Stack spacing={1}>
            {label && <label htmlFor={label + "_id"}>
                <Typography variant="body1" sx={{ color: '#303436bf' }}>
                    {label}
                </Typography>
            </label>}
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker {...props} sx={{
                        ...textFieldStyles
                    }} slots={{
                        openPickerIcon: CalendarTodayRoundedIcon
                        // inputAdornment: () => <InputAdornment position='start'>
                        //     <CalendarTodayRoundedIcon />
                        // </InputAdornment>
                    }} />
                </DemoContainer>
            </LocalizationProvider>
        </Stack>
    );
}
