import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import palette from '@utils/palette';

const textFieldStyles = {
    "& .MuiInputBase-root": {
        flexDirection: 'row-reverse'
    },
    "& .MuiInputBase-input": {
        padding: ".75rem 1.25rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: '#30343624',
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: '#30343624',
            borderRadius: ".5rem",
        },
        "&:not(.Mui-error):hover fieldset": {
            borderColor: '#30343624',
        },
        "&.Mui-focused:not(.Mui-error) fieldset": {
            borderColor: '#30343624',
        },
    },

    "& .MuiInputLabel-root": {
        transform: "translate(16px, 12px) scale(1)",
        "&.Mui-focused, &.MuiFormLabel-filled": {
            transform: "translate(16px, -9px) scale(0.75)",
        },
    },
    "& .MuiInputLabel-root:not(.Mui-error)": {
        color: palette.grey[40],
    },
    "& label": {
        fontSize: "1rem",
        lineHeight: 1.5,
        fontWeight: 400,
    },
    '& input': {
        borderRadius: ".5rem",
    },
    '& svg': {
        color: '#303436',
        opacity: .35
    },
    '&.MuiFormControl-root.MuiTextField-root': {
        minWidth: '0 !important'
    },
};

export default function BasicTimePicker(props:any) {
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DemoContainer components={['TimePicker']}>
                <TimePicker {...props} sx={{
                    ...textFieldStyles
                }} />
            </DemoContainer>
        </LocalizationProvider>
    );
}