// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDDoOZO7fc8By8Qar_0O2fs9-qkPCQsKhQ",
    authDomain: "getdone-fda71.firebaseapp.com",
    projectId: "getdone-fda71",
    storageBucket: "getdone-fda71.appspot.com",
    messagingSenderId: "652039518144",
    appId: "1:652039518144:web:e505ada51b103a10053a25",
    measurementId: "G-092XZN6RLF"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

onAuthStateChanged(auth, (user: any) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log(user)
    auth.currentUser?.getIdToken(true);
    // ...
  } else {
    // User is signed out
    // ...
  }
});

export const logoutFirebase = () => {
  auth.signOut();
}

const googleAuthProvider = new GoogleAuthProvider();
export const signInWithGoogle = () => {
  return signInWithPopup(auth, googleAuthProvider);
};
export default app;
