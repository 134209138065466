import { useEffect, useState } from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@utils/theme';
import RootRouter from '@routers/RootRouter';
import { useAuth } from '@contexts/AuthContext';
import { IntlProvider } from 'react-intl';
import { Toaster } from 'react-hot-toast';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import './modifiedStyling.css';

export default function App() {
  const queryClient = new QueryClient();
  const { user } = useAuth();

  const [messages, setMessages] = useState(null as any);
  const [userLang, setUserLang] = useState('');

  useEffect(() => {
    if(user && user.lang){
      setUserLang(user.lang);
    }
  }, [user]);

  useEffect(() => {
    let lang = user?.lang;
    if(!lang) {
      if(navigator && navigator.language) {
        if(navigator.language.indexOf('es') >= 0) {
          lang = 'es';
        } else if(navigator.language.indexOf('en') >= 0) {
          lang = 'en';
        }
      }
    }
    setUserLang(lang ?? 'en');
  }, []);

  useEffect(() => {
    let lang = userLang;
    if(lang) {
      const url = `https://api.i18nexus.com/project_resources/translations/${lang}/default.json?api_key=d9nRBy0103h06a7MuybLug`;
      
      fetch(url)
        .then(response => response.json())
        .then(data => {
          setMessages(data);
        });
    }
  }, [userLang]);

  return (
    <IntlProvider locale={user?.lang ?? 'en'} messages={messages}>
      <AddToHomeScreen
        appId='com.getdone.app'
        startAutomatically={ true }
        startDelay={ 0 }
        displayPace={ 0 }
        lifespan={ 300 }
        customPromptContent={ {
          title: 'Install Getdone',
          src: '/logo192.png',
          cancelMsg: '',
          installMsg: 'Install',
          guidanceCancelMsg: ''
        } }
        customPromptElements={ {
          container: 'athContainer',
          containerAddOns: '',
          banner: 'athBanner',
          logoCell: 'athLogoCell',
          logoCellAddOns: 'athContentCell',
          logo: 'athLogo',
          titleCell: 'athTitleCell',
          titleCellAddOns: 'athContentCell',
          title: 'athTitle',
          cancelButtonCell: 'athCancelButtonCell',
          cancelButtonCellAddOns: 'athButtonCell',
          cancelButton: 'athCancelButton',
          installButtonCell: 'athInstallButtonCell',
          installButtonCellAddOns: 'athButtonCell',
          installButton: 'athInstallButton',
          installButtonAddOns: 'button',
          guidance: 'athGuidance',
          guidanceImageCell: 'athGuidanceImageCell',
          guidanceImageCellAddOns: '',
          guidanceCancelButton: 'athGuidanceCancelButton'
        } }
      />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RootRouter />
          <Toaster position="top-center" />
        </ThemeProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}
