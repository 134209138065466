import {
    InputBase,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    Typography
} from '@mui/material';
import { ReactNode } from 'react';
import { CustomSelect } from '@ui/select';

export default function TechSelector({ techList = [], selectedTech, input, renderValue, sx = {}, onChange }: { techList?: any[], selectedTech?: any, sx?: any, input?: ReactNode, onChange: (tech: any) => void, renderValue?: (displayValue: any) => (value: any) => any }) {

    return (
        <>
            <CustomSelect
                sx={sx}
                {...(input ? { input } : {})}
                value={selectedTech?.id || null}
                onChange={(event: SelectChangeEvent) => {
                    const sp = (techList || []).find((p) => p.id === (event.target.value as string)) || null;
                    onChange(sp);
                }}
                renderValue={renderValue ? renderValue(selectedTech?.name) : ((value: any) => (
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 400,
                            color: '#5A6A72'
                        }}
                    >
                        {selectedTech?.name}
                    </Typography>
                ))}
            >
                {(techList ?? []).map((p, idx) => {
                    return (
                        <MenuItem key={idx} value={p.id}>
                            {p.name}
                        </MenuItem>
                    );
                })}
            </CustomSelect>
        </>
    );
}
