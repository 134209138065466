import {
  MenuItem,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { ReactNode } from 'react';
import { useAuth } from '@contexts/AuthContext';
import { CustomSelect } from '@ui/select';

export default function PropertySelector({ input, renderValue, sx = {} }: { sx?: any, input?: ReactNode, renderValue?: (displayValue: any) => (value: any) => any }) {
  const { user, setUser, updateTechList } = useAuth();

  return (
    <>
      <CustomSelect
        sx={sx}
        {...(input ? { input } : {})}
        value={user?.selectedProperty?.id || null}
        onChange={(event: SelectChangeEvent) => {
          const sp = (user?.properties || []).find((p) => p.id === (event.target.value as string)) || null;
          updateTechList(sp);
        }}
        renderValue={renderValue ? renderValue(user?.selectedProperty?.name) : ((value: any) => (
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              color: '#5A6A72'
            }}
          >
            {user?.selectedProperty?.name}
          </Typography>
        ))}
      >
        {(user?.properties ?? []).map((p, idx) => {
          return (
            <MenuItem key={idx} value={p.id}>
              {p.name}
            </MenuItem>
          );
        })}
      </CustomSelect>
    </>
  );
}
