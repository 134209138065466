import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Fade,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
  styled,
  accordionSummaryClasses,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkbox.svg';
import { ReactComponent as CheckboxTickedIcon } from '../../assets/icons/checkbox-ticked.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SummaryBox = styled(AccordionSummary)(() => ({
  [`&.${accordionSummaryClasses.root}`]: {
    minHeight: '0'
  },
  [`&.${accordionSummaryClasses.root} .${accordionSummaryClasses.content}`]: {}
}));

interface IMenuProps {
  defaultValues: IOptionProps[];
  options: IOptionProps[];
  type: 'Single' | 'Multiple';
  onSelected: (event: IOptionProps, selected: IOptionProps[]) => void;
  isAccordion: boolean;
  isCard: boolean;
}

interface IAccordionProps extends IMenuProps {
  name: string;
  isExpand: boolean;
  placeholder?: string;
  label?: string;
}

const SelectInAccordion = ({
  name,
  label,
  defaultValues,
  options,
  type,
  onSelected,
  isExpand,
  isCard
}: IAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(isExpand);
  const [dataValue, setDataValue] = useState(defaultValues);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    setDataValue(defaultValues);
  }, [defaultValues]);
  return (
    <Accordion
      sx={{ boxShadow: 'none' }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          px: 0
        }}
      >
        <Typography variant="body1" fontWeight={500} color={'#5A6A72'}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, px: 0 }}>
        <SelectMenu
          defaultValues={dataValue}
          options={options}
          type={type}
          onSelected={onSelected}
          isAccordion
          isCard={isCard}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const SelectInChip = ({
  name,
  placeholder,
  label,
  defaultValues,
  options,
  type,
  onSelected,
  isExpand,
  isCard
}: IAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(isExpand);
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [dataValue, setDataValue] = useState(defaultValues);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    setDataValue(defaultValues);
  }, [defaultValues]);

  return (
    <>
      <Accordion
        sx={{ boxShadow: 'none' }}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            px: 0
          }}
        >
          <Typography variant="body1" fontWeight={500} color={'#5A6A72'}>
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <AutocompleteGetDone
            multiple
            limitTags={2}
            value={dataValue}
            options={[]}
            onOpen={(event: any) => {
              setSelectExpanded(!selectExpanded);
            }}
            onChange={(event, value, reason) => {
              if (reason === 'removeOption') {
                const values = value as IOptionProps[];
                const removed = dataValue?.find(
                  (x) => !values.find((z) => z.name == x.name)
                );
                if (removed) onSelected(removed, dataValue);
              }
            }}
            renderInput={(params) => {
              return (
                <AutocompleteInput
                  {...params}
                  placeholder={dataValue?.length == 0 ? placeholder : ''}
                  variant="outlined"
                />
              );
            }}
            getOptionLabel={(option: any) => option.name}
            inputMode="none"
            className={dataValue?.length > 0 ? 'added' : ''}
            freeSolo
            disableClearable
          ></AutocompleteGetDone>

          <Accordion
            sx={{ boxShadow: 'none' }}
            expanded={selectExpanded}
            disableGutters
          >
            <SummaryBox></SummaryBox>
            <AccordionDetails sx={{ p: 0 }}>
              <SelectMenu
                defaultValues={dataValue}
                options={options}
                type={type}
                onSelected={onSelected}
                isAccordion
                isCard={isCard}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const SelectMenu = ({
  options,
  defaultValues,
  type,
  onSelected,
  isAccordion,
  isCard
}: IMenuProps) => {
  const isMobile = useIsMobile();
  const [dataValue, setDataValue] = useState(defaultValues);

  useEffect(() => {
    setDataValue(defaultValues);
  }, [defaultValues]);

  return (
    <>
      <Grid container spacing={2}>
        {isCard &&
          options.map((opt: IOptionProps, i: number) => {
            return (
              <Grid key={i} item xs={6}>
                {type === 'Multiple' && (
                  <>
                    <Card
                      variant="outlined"
                      onClick={(e) => onSelected(opt, dataValue)}
                      sx={{
                        border:
                          dataValue?.map((e) => e.id).indexOf(opt.id) > -1
                            ? '2px solid #313436'
                            : 'border: 1px solid #30343624',
                        borderRadius: '8px',
                        cursor: 'pointer'
                      }}
                    >
                      <CardContent>
                        {opt.icon && (
                          <ListItemIcon
                            sx={{
                              color: opt.color
                            }}
                          >
                            {opt.icon}
                          </ListItemIcon>
                        )}

                        <Typography
                          variant="body1"
                          color={opt.color}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {opt.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                )}
              </Grid>
            );
          })}
      </Grid>

      {!isCard &&
        options.map((opt: IOptionProps, i: number) => {
          return (
            <MenuItem
              key={i}
              value={opt.id}
              onClick={(e) => onSelected(opt, dataValue)}
              sx={{
                px: isMobile && isAccordion ? 0 : isMobile ? 2 : 1
              }}
            >
              {type === 'Multiple' && (
                <>
                  {!isMobile && (
                    <Checkbox
                      checked={
                        defaultValues?.map((e) => e.id).indexOf(opt.id) > -1
                      }
                      onClick={(e) => onSelected(opt, dataValue)}
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxTickedIcon />}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 24,
                          borderRadius: '4px'
                        }
                      }}
                    />
                  )}
                  {opt.icon && (
                    <ListItemIcon
                      sx={{
                        color: opt.color
                      }}
                    >
                      {opt.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body1"
                        color={opt.color}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {opt.name}
                      </Typography>
                    }
                  />
                  {isMobile && (
                    <Checkbox
                      checked={
                        defaultValues?.map((e) => e.id).indexOf(opt.id) > -1
                      }
                      onClick={(e) => onSelected(opt, dataValue)}
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxTickedIcon />}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 24,
                          borderRadius: '4px'
                        }
                      }}
                    />
                  )}
                </>
              )}
              {type === 'Single' && (
                <>
                  {opt.icon && (
                    <ListItemIcon sx={{ color: opt.color }}>
                      {opt.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        color={opt.color}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {opt.name}
                      </Typography>
                    }
                  />
                  {opt.description && (
                    <Typography variant="body2" color="#30343659">
                      <Tooltip title={opt.description}>
                        <IconButton>
                          <ErrorIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  )}
                </>
              )}
            </MenuItem>
          );
        })}
    </>
  );
};

const AutocompleteInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '4px 5px'
  }
}));

const AutocompleteGetDone = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    marginBottom: '5px'
  },
  '& .MuiAutocomplete-clearIndicator': {
    color: theme.palette.primary.main,
    backgroundColor: '#E6ECEF'
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '6px 5px'
  }
}));

interface IOptionProps {
  id: string;
  name: string;
  description?: string;
  color?: string;
  icon?: React.ReactNode;
}

interface IProps {
  name: string;
  defaultValues: IOptionProps[];
  options: IOptionProps[];
  control: any;
  type: 'Single' | 'Multiple';
  rules?: any;
  btnLabel?: string;
  label?: any;
  placeholder?: string;
  subTitile?: string;
  startIcon?: React.ReactNode;
  isAccordion?: boolean;
  isExpand?: boolean;
  isCard?: boolean;
  btnClick?: any;
  selectedValue?: any;
}

export default function SelectInput({
  name,
  defaultValues,
  options,
  control,
  type,
  rules,
  btnLabel,
  label,
  placeholder,
  startIcon,
  isAccordion,
  isExpand,
  isCard,
  btnClick,
  selectedValue
}: IProps) {
  const isMobile = useIsMobile();
  const [selectedValues, setSelectedValues] = useState<any[]>(defaultValues);
  const autoRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [optionValues, setOptionValues] = useState<IOptionProps[]>(options);
  const [width, setWidth] = useState(0);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  useLayoutEffect(() => {
    const current = autoRef?.current as any;
    setWidth(current?.offsetWidth);
    if(selectedValue && selectedValue.length) {
      setSelectedValues(selectedValue);
    } else {
      setSelectedValues(defaultValues);
    }
  }, [selectedValue]);

  let formOnChange: any = null;
  let onSelected = (value: any, selected: any[]) => {
    if (selected.map((e) => e.id).indexOf(value.id) > -1) {
      const data = selected.filter(function (e: any) {
        return e.id !== value.id;
      });
      setSelectedValues(data);
      formOnChange(data);
    } else {
      const data = [...selected, value];
      setSelectedValues(data);
      formOnChange(data);
    }
  };

  // useEffect(() => {
  //   setSelectedValues(defaultValues ?? []);
  // }, [defaultValues]);

  return (
    <Box py={isMobile ? 0 : 1}>
      {!isMobile && (
        <InputLabel htmlFor={name} sx={{ pb: '8px' }}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        //defaultValue={type === 'Single' ? '' : selectedValues}
        defaultValue={selectedValues}
        render={({ field: { onChange, value } }) => {
          formOnChange = onChange;
          if (isAccordion) {
            return (
              <SelectInAccordion
                name={name}
                label={label}
                options={optionValues}
                defaultValues={value}
                type={type}
                onSelected={onSelected}
                isExpand={isExpand ?? false}
                isAccordion
                isCard={isCard ?? false}
              />
            );
          }

          if (isMobile) {
            return (
              <SelectInChip
                name={name}
                label={label}
                options={optionValues}
                defaultValues={value}
                type={type}
                onSelected={onSelected}
                isExpand={isExpand ?? false}
                isAccordion
                placeholder={placeholder}
                isCard={isCard ?? false}
              />
            );
          }

          return (
            <AutocompleteGetDone
              ref={autoRef}
              //multiple={type === 'Multiple'}
              multiple
              fullWidth
              options={[]}
              value={value}
              onClose={(event: any) => {
                if(event.type !== 'blur')
                  setOpen(false);
              }}
              onOpen={(event: any) => {
                setOpen(true);
                setAnchorEl(autoRef.current);
              }}
              onKeyDown={(e: any) => e.preventDefault()}
              onInputChange={(event: any) => {
                var value = event.currentTarget.value;
                if (value) {
                  var filtered = optionValues.filter((v: any) =>
                    v.name.toLowerCase().includes(value.toLowerCase())
                  );
                  setOptionValues(filtered);
                } else {
                  setOptionValues(optionValues);
                }
              }}
              onChange={(event, value, reason) => {
                if (reason === 'clear') {
                  setSelectedValues([]);
                  formOnChange([]);
                  setOptionValues(optionValues);
                  setOpen(false);
                }
              }}
              onFocus={(event) => {
                setFocus(true);
              }}
              onBlur={(event) => {
                setFocus(false);
              }}
              renderInput={(params) => {
                const inputProps = {
                  ...params.InputProps,
                  ...{
                    sx: {
                      height: '48px',
                      borderRadius: '8px',
                      bgcolor: selectedValues.length > 0 ? '#4eaa76' : null,
                      border:
                        '1px solid var(--dark-disabled, rgba(48, 52, 54, 0.14))'
                    },
                    startAdornment: (
                      <>
                        <InputAdornment
                          position="start"
                          sx={{
                            color: selectedValues.length > 0 ? '#FFF' : null
                          }}
                        >
                          {startIcon}
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    )
                  }
                };
                return (
                  <AutocompleteInput
                    sx={{caretColor: 'transparent'}}
                    {...params}
                    placeholder={selectedValues.length == 0 ? placeholder : ''}
                    variant="outlined"
                    InputProps={inputProps}
                  />
                );
              }}
              renderTags={(value: readonly any[], getTagProps) => {
                return (
                  <Box
                    sx={{
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'space-between',
                      pb: 1,
                      color: '#FFF'
                    }}
                  >
                    <Typography
                      variant="body1"
                      pl={0.5}
                      pt={0.5}
                      color={'#FFFFFF'}
                    >
                      (+{value.length}) Selected
                    </Typography>
                  </Box>
                );
              }}
              inputMode="search"
              className={selectedValues.length > 0 ? 'added' : ''}
              freeSolo
              disableClearable
              openOnFocus={true}
            />
          );
        }}
      />

      <Popper
        open={open}
        anchorEl={anchorEl}
        disablePortal={false}
        placement={isMobile ? 'bottom' : 'bottom-end'}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: 'document',
              padding: 8
            }
          }
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              if (open && !focus) setOpen(false);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{ bgcolor: '#FFF' }}
                style={{
                  marginTop: 3,
                  borderBottomRightRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  border: '1px solid #D9E2EC',
                  width: width,
                  maxHeight: '444px'
                }}
              >
                <Box
                  sx={{
                    maxHeight: '380px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    pt: 2
                  }}
                >
                  <SelectMenu
                    defaultValues={selectedValues}
                    options={optionValues}
                    type={type}
                    onSelected={onSelected}
                    isAccordion={false}
                    isCard={false}
                  />
                </Box>
                {btnLabel && type === 'Multiple' && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      py: 2,
                      px: 2
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      onClick={() => {
                        setOpen(false);
                        btnClick && btnClick();
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        color={'#FFF'}
                        textTransform={'none'}
                      >
                        {btnLabel}
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
}
