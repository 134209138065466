import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Chip, Tab, Tabs, Typography, styled } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

interface IOptionProps {
  id: any;
  name: string;
}

interface IGridFilterProps {
  options: IOptionProps[];
  value: number;
  onChange?: (newValue: any) => void;
  count?: number;
}

const ScrollingBox = styled(Box)(() => ({
  overflow: 'auto',
  scrollbarWidth: 'none', // Hide the scrollbar for firefox
  '&::-webkit-scrollbar': {
    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  '&-ms-overflow-style:': {
    display: 'none' // Hide the scrollbar for IE
  }
}));

export default function GridFilter({
  options,
  value,
  onChange,
  count
}: IGridFilterProps) {
  const isMobile = useIsMobile();
  const [valueTab, setValueTab] = useState<any>(value);
  const handleTabChange = (event: SyntheticEvent, newValue: any) => {
    setValueTab(newValue);
    onChange && onChange(newValue);
  };

  const handleMobileTabChange = (opt: IOptionProps) => {
    setValueTab(opt.id);
    onChange && onChange(opt.id);
  };

  return (
    <>
      {!isMobile && (
        <Tabs value={valueTab} onChange={handleTabChange}>
          {options.map((opt, idx) => (
            <Tab
              key={idx}
              icon={
                <>
                  <Typography
                    variant="h5"
                    fontWeight={valueTab == opt.id ? 500 : 400}
                    lineHeight={'28px'}
                    pr={1}
                    color={valueTab == opt.id ? '#4EAA76' : '#8D9DA5'}
                  >
                    {opt.name}
                  </Typography>
                  {valueTab == opt.id && (
                    <Chip
                      label={count ?? ''}
                      sx={{
                        color: valueTab == opt.id ? '#4EAA76' : '#303436BF',
                        bgcolor: valueTab == opt.id ? '#D6F9E5' : '#3034360D'
                      }}
                    />
                  )}
                </>
              }
              sx={{ color: '#8D9DA5', pl: '4px' }}
            />
          ))}
        </Tabs>
      )}
      {isMobile && (
        <ScrollingBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1,
            pb: 2
          }}
        >
          {options.map((opt, idx) => (
            <Box key={idx}>
              <Chip
                label={opt.name}
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  border: valueTab == opt.id ? 'inherit' : '1px solid #89B5E0',
                  bgcolor:
                    valueTab == opt.id ? '#CEE6FF !important' : 'inherit',
                  color: valueTab == opt.id ? '#2B363B' : '#5A6A72'
                }}
                onClick={() => handleMobileTabChange(opt)}
              />
            </Box>
          ))}
        </ScrollingBox>
      )}
    </>
  );
}
