import { Box, Grid, Stack, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import { useIsMobile, useIsTab } from "@hooks/useIsMobile";
import { useEffect, useMemo, useState } from "react";


export default function WorkOrderBreakDownChart({graphsData}: any) {
    const isMobile = useIsMobile();
    const isTab = useIsTab();
    const [data, setData] = useState<any>({});
    const [rows, setRows] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    const colors =['#0C6B58',
    '#199473',
    '#8EEDC7',
    '#E2F9EE'];

    useEffect(() => {
      if(graphsData && graphsData.wosByStatus) {
        let respArr = [];
        let totalL = 0;
        for(let k of Object.keys(graphsData.wosByStatus)){ 
          respArr.push({key: k, val: graphsData.wosByStatus[k].length});
          totalL += graphsData.wosByStatus[k].length;
        };
        setTotal(totalL);
        respArr = respArr.sort((a: any, b: any) => b.val - a.val);
        setRows(respArr);
        setData({
          labels: respArr.map((r) => r.key) ,
          datasets: [
            {
              data: respArr.map((r) => r.val),
              backgroundColor: colors,
            }
          ]
        });
      }
    }, [graphsData]);

    // const data: ChartData<"doughnut"> = {
    //     labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
    //     datasets: [
    //         {
    //             label: 'Dataset 1',
    //             data: [20, 40, 30, 10],
    //             backgroundColor: Object.values(['#0C6B58',
    //                 '#199473',
    //                 '#8EEDC7',
    //                 '#E2F9EE']),
    //         }
    //     ]
    // };

    const options: ChartOptions<"doughnut"> = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                mode: 'index',
                intersect: false,
                external: function(context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');
    
                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }
                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }
                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }
                    function getBody(bodyItem: any) {
                        return bodyItem.lines;
                    }
                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);
                        let innerHtml = '<tbody>';
                        bodyLines.forEach(function(body, i) {
                            const colors = tooltipModel.labelColors[i];
                            let style = 'background: #fff';
                            style += '; display: flex';
                            style += '; flex-direction: column';
                            style += '; padding: 4px 8px';
                            style += '; justify-content: center';
                            style += '; align-items: center';
                            style += '; border: 1px solid var(--dark-disabled, rgba(48, 52, 54, 0.14))';
                            style += '; border-radius: 5px';
                            style += '; font-size: 14px';
                            style += '; box-shadow: 0px 2px 8px 0px rgba(48, 52, 54, 0.08)'
                            const span = '<span style="' + style + '"><span>' + titleLines[0] + '</span><span>' +  body + '</span></span>';
                            innerHtml += '<tr><td>' + span + '</td></tr>';
                        });
                        innerHtml += '</tbody>';
                        let tableRoot = tooltipEl.querySelector('table');
                        tableRoot!!.innerHTML = innerHtml;
                    }
                    const position = context.chart.canvas.getBoundingClientRect();
                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 20 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 50 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            // @ts-ignore
            'sjk': {
                total: total
            }
        },
        radius: 100,
        cutout: '65%',
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }
    const plugins = useMemo(() => {
        return [{
            id: 'sjk',
            beforeDraw: (chart: ChartJS, args: any, options: any) => {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                ctx.font = "2.5rem sans-serif";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#303436";
                var text = `${options.total}`,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = (height / 2) - 8;

                ctx.fillText(text, textX, textY);
                ctx.font = ".875rem sans-serif";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#5A6A72";
                var text1 = "Work Orders",
                textX1 = Math.round((width - ctx.measureText(text1).width) / 2),
                textY1 = (height / 2) + 20;
                ctx.fillText(text1, textX1, textY1);
                ctx.save();
            }
        }]
    }, [total]);

    const LegendItem = ({ label, description, color }: { label: string, description: string, color: string }) => {
        return <Stack direction="row">
            <Box>
                <Box sx={{
                    width: '.5rem',
                    height: '1rem',
                    backgroundColor: color,
                    borderRadius: '1.5rem',
                    mr: 1,
                    mt: .5
                }}></Box>
            </Box>
            <Box sx={{ flex: 1 }}>
                <Typography variant="body1" sx={{ mb: .5 }}>{label}</Typography>
                <Typography variant="body2" sx={{ color: '#5A6A72', fontWeight: 400 }}>{description}</Typography>
            </Box>
        </Stack>
    }


    const getLegends = () => {
        return <Grid container spacing={3} sx={{ ml: isTab ? 0 : 22 / 8 }} >
            {rows && rows.length && rows.map((row, index) => (
                <Grid item xs={isTab ? 6 : 12}>
                    <LegendItem label={row.key} color={colors[index]} description={row.val + ' Work Orders'} />
                </Grid>
            ))}
        </Grid>
    };

    return <>
        {
            isTab ?
                <Stack>
                    <Stack sx={{ height: '14rem' }} alignItems="center">
                    {data && data.labels && <Doughnut data={data} options={options} plugins={plugins} />}
                    </Stack>
                    <Box sx={{ mt: 2 }}>
                        {getLegends()}
                    </Box>
                </Stack>
                : <Grid container>
                    <Grid item xs={5} sx={{
                        borderRight: '1px solid rgba(48, 52, 54, 0.14)'
                    }}>
                        {getLegends()}
                    </Grid>
                    <Grid item xs={7}>
                        <Stack justifyContent="center" alignItems="center">
                        {data && data.labels && <Doughnut data={data} options={options} plugins={plugins} />}
                        </Stack>
                    </Grid>
                </Grid>
        }

    </>
}