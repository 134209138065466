import { Button, Drawer, Grid, MenuItem, Stack, Typography } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ButtonCover } from "@ui/button";
import { useIsMobile } from "@hooks/useIsMobile";
import palette from "@utils/palette";
import { Controller, useForm } from "react-hook-form";
import { FormInputField, FormSelectField } from "@ui/form";
import { useEffect } from "react";
import axios from "axios";
import { useAuth } from "@contexts/AuthContext";
import { postRequest } from "@utils/http.service";
import { getInitials } from "@utils/helpers";

interface IAddEditUser {
    isEditMode?: boolean;
    user: any | null;
    handleClose: (refresh?: boolean) => void;
    open: boolean;
    onRemove: (user: any) => void;
}

export default function AddEditUser({ open, isEditMode, handleClose, user, onRemove }: IAddEditUser) {

    const isMobile = useIsMobile();
    const auth = useAuth();

    const {
        control,
        formState: { errors, isValid },
        setValue,
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            role: "",
        },
        mode: "onTouched",
    });

    useEffect(() => {
        if (isEditMode && user) {
            const { username, properties, roles, firstName, lastName } = user;
            reset({ firstName, lastName, email: username, role: roles[0].role });
        }
    }, [user]);

    const saveUser = async(data: any) => {
        await postRequest(`users`, {...data, username: data.email, properties: [auth.user!!.selectedProperty.id]});
        handleClose(true);
    }

    return <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={open}
        sx={{
            // zIndex: '1400 !important',
            '& .MuiPaper-root': {
                width: isMobile ? '100%' : 640,
                maxWidth: isMobile ? '100%' : 640,
                backgroundColor: '#F5F8FA'
            }
        }}
    >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 2.5, px: 3, boxShadow: '0px .125rem .5rem 0px rgba(48, 52, 54, 0.08)', backgroundColor: palette.white }}>
            <ButtonCover onClick={() => handleClose(false)}>
                <CloseRoundedIcon sx={{ color: '#303436bf', width: '2rem', height: '2rem' }} />
            </ButtonCover>
            <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.2, fontWeight: 400, color: '#5A6A72' }}>{isEditMode ? 'Edit' : 'Add'} User</Typography>
            <Button></Button>
        </Stack>
        <Stack sx={{ mt: 3, backgroundColor: palette.white, px: 3 }}>
            <Stack direction="row" justifyContent="center" sx={{ py: 4 }}>
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: '7.5rem', width: '7.5rem', postion: 'relative', borderRadius: '100%', backgroundColor: '#FF9E0D', opacity: .8, border: '1px solid #FF9E0D' }}>
                    <Typography sx={{
                        fontSize: '3.5rem', lineHeight: 60 / 56, color: palette.white, fontWeight: 500
                    }}>{getInitials(user?.firstName + ' ' + user?.lastName)}</Typography>
                </Stack>
            </Stack>
            <Grid container columnSpacing={3} rowSpacing={1}>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{
                            required: "First Name is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormInputField
                                    label="First Name"
                                    placeholder="Enter First Name"
                                    {...field}
                                    error={errors?.firstName}
                                    helperText={errors?.firstName?.message}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="lastName"
                        control={control}
                        rules={{
                            required: "Last Name is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormInputField
                                    label="Last Name"
                                    placeholder="Enter Last Name"
                                    {...field}
                                    error={errors?.lastName}
                                    helperText={errors?.lastName?.message}
                                />
                            );
                        }}
                    />                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: "Email is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormInputField
                                    label="Email"
                                    placeholder="Enter email"
                                    {...field}
                                    error={errors?.email}
                                    helperText={errors?.email?.message}
                                />
                            );
                        }}
                    />                </Grid>
                <Grid item xs={12} sm={6}>
       
                    <Controller
                        name="role"
                        control={control}
                        rules={{
                            required: "Role is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Role"
                                    {...field}
                                    error={errors?.role}
                                    helperText={errors?.role?.message}
                                >
                                    <MenuItem value={"tech"}>Technician</MenuItem>
                                    <MenuItem value={"pm"}>Property Manager</MenuItem>
                                    <MenuItem value={"supervisor"}>Maintenance Supervisor</MenuItem>
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                {/* <Grid item xs={12} sx={{ mt: 3 }}>
                    <Controller
                        name="properties"
                        control={control}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    {...field}
                                    label="Properties"
                                    multiple
                                    renderValue={(selected: any) => {
                                        return (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    gap: 6 / 8,
                                                }}
                                            >
                                                {selected.map((value: any, index: number) => {
                                                    const prop = properties.find((pro) => pro.id == value);
                                                    return <SelectChip
                                                        key={value}
                                                        label={prop?.name}
                                                        onDelete={() => {
                                                            const copy = [...selected];
                                                            copy.splice(index, 1);
                                                            setValue("properties", copy);
                                                        }}
                                                    />
                                                })}
                                            </Box>
                                        );
                                    }}
                                >
                                    {(properties || []).map((property) => (
                                        <MenuItem key={property.id} value={property.id}>
                                            <ListItemText primary={property.name} />
                                        </MenuItem>
                                    ))}
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid> */}
            </Grid>
            {isEditMode && <Button sx={{ mt: 3, color: '#CF1124' }} onClick={() => onRemove(user)}>Remove User</Button>}
            <Stack direction="row" spacing={3} justifyContent="flex-end" sx={{ my: 7 }}>
                <Button variant="outlined" fullWidth={isMobile} onClick={() => {handleClose(false)}}>Cancel</Button>
                <Button variant="contained" fullWidth={isMobile} sx={{ color: palette.white }} onClick={handleSubmit(saveUser)}>Save Changes</Button>
            </Stack>
        </Stack>
    </Drawer>
}