import PageHeader from '@components/PageHeader';
import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { getColorByText, getInitials } from '@utils/helpers';
import { useEffect, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PropertySelector from '@components/inputs/PropertySelect';
import { useIsMobile } from '@hooks/useIsMobile';
import { DateTime } from 'luxon';
import { useAuth } from '@contexts/AuthContext';
import WorkOrderDetail from '@components/workOrder/Detail';
import palette from '@utils/palette';
import { MobileSelect } from './DashboardPage';
import SelectDrawer from '@components/drawers/SelectDrawer';
import { getRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';

function createData(
  id: any,
  name: string,
  description: string,
  workOrderNumber: string,
  date: Date,
  workOrderId: any
) {
  return { id, name, description, workOrderNumber, date, workOrderId };
}

export default function NotificationsPage() {
  const isMobile = useIsMobile();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  const renderNameTag = (name: string) => {
    return (
      <Box
        sx={{
          width: '48px',
          height: '48px',
          lineHeight: '48px',
          border: '1px solid #00000014',
          borderRadius: '50%',
          textAlign: 'center',
          background: getColorByText(name),
          color: '#fff'
        }}
      >
        {getInitials(name)}
      </Box>
    );
  };

  const renderDate = (date: Date) => {
    const dateTime = DateTime.fromJSDate(date).setLocale('en-us');
    const diff = dateTime.diffNow([
      'years',
      'months',
      'days',
      'hours',
      'minutes'
    ]);
    if (
      diff.years == 0 &&
      diff.months == 0 &&
      diff.days == 0 &&
      diff.hours == 0
    ) {
      return `${-Math.ceil(diff.minutes)} mins ago`;
    }

    if (diff.years == 0 && diff.months == 0 && diff.days == 0) {
      return `${-diff.hours} ${-diff.hours == 1 ? 'hour' : 'hours'} ago`;
    }

    if (diff.years == 0 && diff.months == 0) {
      return `${-diff.days} ${-diff.days == 1 ? 'day' : 'days'} ago`;
    }

    return isMobile
      ? dateTime.toLocaleString({
          month: '2-digit',
          year: '2-digit'
        })
      : dateTime.toLocaleString({
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          year: '2-digit'
        });
  };

  const [currentWo, setCurrentWo] = useState<any>(null);

  const {user, updateTechList} = useAuth();

  const [rows, setNotifications] = useState<any[]>([]);

  const createNData = (data: any[]) => {
    const dm: any[] = [];
    data.forEach((d: any) => {
      let ss = '';
      switch(d.type) {
        case 'Completed': 
          ss = `Work Order #{WORK_ORDER} is finished`;
          break;
        case 'In Progress':
          ss = 'Work Order #{WORK_ORDER} is put to progress';
          break;
        case 'On Hold':
          ss = 'Work Order #{WORK_ORDER} was put on hold';
          break;
        case 'comment':
          ss = 'Added a comment on Work Order #{WORK_ORDER}';
          break;
        default:
          ss = `Work Order #{WORK_ORDER} was updated to ${d.type}`;
      }
      dm.push(createData(
        d.id,
        d.fromUserName,
        ss,
        d.workOrderNumber,
        new Date(d.createdAt),
        d.workOrderId
      ));
    });
    return dm;
  }

  const getNotifications = async() => {
    const resp = await getRequest(`notifications?propertyId=${user?.selectedProperty.id}`, {
      headers: {
          Authorization: `Bearer ${user!!.token}`
      }
    });
    setNotifications(createNData(resp.data));
  }

  const selectWo = async(id: string) => {
    const resp = await getRequest(`work-orders/${id}`, {
      headers: {
          Authorization: `Bearer ${user!!.token}`
      }
    });
    setCurrentWo(resp.data);
  }

  useEffect(()=> {
    getNotifications();
  }, [paginationModel, user]);

  enum DrawerType {
    DateFilter = 'DateFilter',
    PropertyFilter = 'PropertyFilter'
  }

  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });


  return (<>
    <Box>
      <PageHeader title={<FormattedMessage id="Notifications" />} selection={(user?.properties?.length || 0) > 1 && <PropertySelector />}/>
      {isMobile ? (
          <Stack direction="row" spacing={2} sx={{ backgroundColor: palette.white, mx: -2, px: 2, mt: -4, pt: 4, mb: 2, pb: 2 }}>
            <Box onClick={() => {
              setDrawer({
                open: true,
                type: DrawerType.PropertyFilter
              })
            }}>
              { (user?.properties?.length || 0) > 1 ? <PropertySelector
                sx={{
                  pointerEvents: 'none'
                }} input={<MobileSelect />}
                renderValue={(displayValue) => (value: any) => displayValue}
              /> : '' }
            </Box>
          </Stack>
        ) : null}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'rgba(48, 52, 54, 0.1) 0px 6px 24px 0px',
          borderRadius: isMobile ? 'none' : '1rem',
          mb: 2
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ borderBottom: isMobile ? 'none' : '1px solid #EDF1F3' }}
              >
                <Typography variant="h4">{<FormattedMessage id='Most Recent' />}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.workOrderNumber}
                sx={{
                  borderBottom: isMobile
                    ? '1px solid #E6ECEF'
                    : '1px solid #EDF1F3'
                }}
              >
                <TableCell scope="row">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 1,
                        alignItems: 'center'
                      }}
                    >
                      <Box>{renderNameTag(row.name)}</Box>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="body1" color={'#5A6A72'}>
                            {row.name}
                          </Typography>
                          <Box
                            sx={{
                              bgcolor: '#5A6A72',
                              height: '4px',
                              width: '4px',
                              borderRadius: '50%'
                            }}
                          ></Box>
                          <Typography variant="body1" color={'#5A6A72'}>
                            {renderDate(row.date)}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body1" color={'#2B363B'}>
                            <FormattedMessage id={row.description} values={{
                              WORK_ORDER: row.workOrderNumber
                            }} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{ cursor: 'pointer', pr: isMobile ? 0 : 2 }}
                      onClick={() => selectWo(row.workOrderId)}
                    >
                      <Typography
                        variant="body1"
                        color={'#57BD83'}
                        sx={{ borderBottom: '1px solid #57BD8333' }}
                      >
                        {isMobile && <FormattedMessage id='View'/>}
                        {!isMobile && <FormattedMessage id='View Work Order'/>}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 5
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography pr={2} pt={1}>
              <FormattedMessage id="Rows Per Page" />
            </Typography>
            <TextField
              select
              label=""
              defaultValue="10"
              size="small"
              onChange={(e) => {
                setPaginationModel({
                  page: 0,
                  pageSize: parseInt(e.target.value, 10)
                });
              }}
              sx={{ bgcolor: '#E8EBEE' }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </TextField>
          </Box>
          <Pagination
            page={paginationModel.page + 1}
            count={Math.ceil(rows.length / paginationModel.pageSize)}
            shape="rounded"
            onChange={(e, page) =>
              setPaginationModel({
                page: page - 1,
                pageSize: paginationModel.pageSize
              })
            }
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: NavigateBeforeIcon,
                  next: NavigateNextIcon
                }}
                {...item}
              />
            )}
          />
        </Box>
      </Grid>
    </Box>
    {currentWo && <WorkOrderDetail wo={currentWo} onClose={() => { setCurrentWo(null); }} />}
    {
        isMobile ?
          <>
            <SelectDrawer
              open={drawer.open && drawer.type === DrawerType.PropertyFilter}
              onClose={() => {
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              options={(user || [] as any).properties.map((p: any) => ({ label: p.name, value: p.id }))}
              onChange={(value) => {
                const sp = (user?.properties || []).find((p) => p.id == (value));
                updateTechList(sp);
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              name="property-filter"
              selected={user!!.selectedProperty.id}
              submitBtnLabel='View Property'
              heading="Select Property to View"
            />
          </> : ''
      }
  </>
  );
}
