import { ListItem, Popover as MuiPopover, styled } from "@mui/material";

export const Popover = styled(MuiPopover)(() => ({
    "& .MuiPaper-root.MuiPopover-paper": {
        borderRadius: ".5rem",
        border: "1px solid #30343624",
        boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)',
        minWidth: '12.5rem',
        padding: '.375rem'
    }
}));

export const PopoverActionListItem = styled(ListItem)(() => ({
    padding: ".625rem .875rem .625rem .875rem",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: '#F5F5F5'
    },
}));