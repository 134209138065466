import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material';
import { useEffect, useState } from 'react';

// function createData(
//   rank: string,
//   service_type: string,
//   work_orders: string,
//   percent: string
// ) {
//   return { rank, service_type, work_orders, percent };
// }

// const rows = [
//   createData('1', 'HVAC', '25', '50%'),
//   createData('2', 'HVAC', '25', '50%'),
//   createData('3', 'HVAC', '25', '50%'),
//   createData('4', 'HVAC', '25', '50%')
// ];

const TableCustom = styled(Table)(({ theme }) => ({
  '& th': {
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 3,
    color: '#303436bf',
    borderBottom: 0,
    padding: '1rem 0 0 0'
  },
  '& td': {
    fontSize: '1rem',
    lineHeight: 1.5,
    padding: '1rem 0',
    borderBottom: '1px solid #30343624',
    '&:first-of-type': {
      paddingLeft: '1rem'
    }
  },
  '& tr:last-child td': {
    borderBottom: 'none'
  }
}));

export default function ServiceTypeBreakDown({graphsData}: any) {

  const [rows, setRows] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(graphsData && graphsData.wosByCat) {
      let respArr = [];
      let total = 0;
      for(let k of Object.keys(graphsData.wosByCat)){ 
        respArr.push({key: k, val: graphsData.wosByCat[k].length});
        total += graphsData.wosByCat[k].length;
      };
      setTotal(total);
      respArr = respArr.sort((a: any, b: any) => b.val - a.val);
      setRows(respArr)
    }
  }, [graphsData]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none'
      }}
    >
      <TableCustom aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Service Type</TableCell>
            <TableCell>Work Orders</TableCell>
            <TableCell>Percent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(0,5).map((row, index) => (
            <TableRow key={row.rank}>
              <TableCell scope="row">{index + 1}</TableCell>
              <TableCell>{row.key}</TableCell>
              <TableCell>{row.val}</TableCell>
              <TableCell>{Math.round(((row.val * 100 / total) + Number.EPSILON) * 100) / 100}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
}
