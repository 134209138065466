import { Box, Grid } from '@mui/material';
import Link from '@components/Link';

interface IProps {
  title: any;
  onClick: () => void;
}

export default function ActionCard({ title, onClick }: IProps) {
  return (
    <Grid
      container
      sx={{
        p: 2,
        background: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 6px 24px 0px #3034361A'
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '40px'
          }}
        >
          <Link text={title} onClick={onClick} variant="h5" />
        </Box>
      </Grid>
    </Grid>
  );
}
