import PrimaryButton from "@components/buttons/PrimaryButton";
import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import palette from "@utils/palette";

interface IConfirm {
    details: any;
    onNext: () => void;
    onBack: () => void;
    isValid: boolean;
    units: any;
}

export default function Confirm({ details, onNext, onBack, isValid, units }: IConfirm) {
    return <>
        <Stack sx={{ mt: 3, backgroundColor: palette.white, px: 3, py: 20 / 8 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, mb: 3, color: '#5A6A72' }}>Confirm Tenant Information</Typography>
            <Stack spacing={3}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: '#5A6A72' }}>Tenant Name</Typography>
                    <Typography variant="body1" sx={{ color: '#2B363B' }}>{units.find((u: any) => u.unitNumber === details?.unitNumber)?.tenantName}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">Unit Number</Typography>
                    <Typography variant="body1">{details?.unitNumber}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">Phone Number</Typography>
                    <Link href={'tel:'+units.find((u: any) => u.unitNumber === details?.unitNumber)?.tenantPhone}>{units.find((u: any) => u.unitNumber === details?.unitNumber)?.tenantPhone}</Link>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">Email</Typography>
                    <Link href={'mailto:'+units.find((u: any) => u.unitNumber === details?.unitNumber)?.tenantEmail}>{units.find((u: any) => u.unitNumber === details?.unitNumber)?.tenantEmail}</Link>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={3} sx={{
                mt: 4,
                '& button': {
                    px: 2.5, py: 14 / 8,
                    borderRadius: '.5rem',
                }
            }}>
                <Button onClick={onBack} fullWidth variant="outlined" sx={{ border: '1px solid rgba(48, 52, 54, 0.35)' }}>Go Back</Button>
                <Button onClick={onNext} fullWidth sx={{
                    backgroundColor: '#57BD83', color: palette.white, '&:hover': {
                        backgroundColor: '##57BD83'
                    }
                }}>Confirm</Button>
            </Stack>
        </Stack>
    </>
}