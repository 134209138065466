import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { theme } from '@utils/theme';
import { useEffect, useState } from 'react';

interface IProps {
  btnText: any;
  onClick?: () => void;
  formState?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
  isSmall?: boolean;
  startIcon?: React.ReactNode;
  style?: any;
}

export default function PrimaryButton({
  btnText,
  onClick,
  formState,
  isDisabled,
  isLoading,
  isFullWidth,
  isSmall,
  startIcon,
  style
}: IProps) {
  formState
    ? formState
    : (formState = { isDirty: true, isValid: true, isSubmitting: false });
  const { isDirty, isValid, isSubmitting } = formState;
  const [disabled, setDisabled] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isValid || isDisabled) return setDisabled(true);
    setDisabled(false);
  }, [isDirty, isValid, isDisabled]);

  return (
    <Button
      variant={'contained'}
      disableElevation
      size="large"
      type="submit"
      onClick={onClick}
      disabled={disabled}
      sx={{
        height: isSmall ? '36px' : '52px',
        width: isMobile || isFullWidth ? '100%' : undefined,
        borderRadius: '8px',
        ...style
      }}
    >
      <>
        {isSubmitting || isLoading ? (
          <CircularProgress size={30} sx={{color: '#fff'}}/>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                color: '#fff',
                pt: 0.5
              }}
            >
              {startIcon}
            </Box>
            <Typography
              variant={isSmall ? 'body2' : 'button'}
              color={
                disabled
                  ? theme.palette.secondary.dark
                  : theme.palette.secondary.main
              }
              pl={0.5}
              fontWeight={isSmall ? 600 : 400}
            >
              {btnText}
            </Typography>
          </Box>
        )}
      </>
    </Button>
  );
}
