import PrimaryButton from '@components/buttons/PrimaryButton';
import TextInput from '@components/inputs/TextInput';
import AuthLayout from '@components/layouts/AuthLayout';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Paper, Typography } from '@mui/material';
import { API_BASE_URL, EMAIL_REGEX, PASSWORD_REGEX } from '@utils/constants';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import { useState } from 'react';
import { postRequest } from '@utils/http.service';

interface IFormInput {
  email: string;
}

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { control, handleSubmit, formState, setError } = useForm<IFormInput>({
    defaultValues: { email: ''},
    mode: 'onChange'
  });
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState('');

  const onSubmit = async ({ email }: IFormInput) => {
    try {
        await postRequest(`auth/forgot-password`, {
            username: email
        });
        setEmailSent(true);
        setEmailSentTo(email);
    } catch (err: any) {
      setError('email', {
        message: err.response.data.message
      });
    }
  };

  return (
    <AuthLayout pageTitle="Log In">
      <Paper
        sx={{
          width: isMobile ? '100%' : '39.5rem',
          px: isMobile ? '1rem' : '7rem',
          py: '1rem',
          borderRadius: '16px',
          boxShadow: '0px 10px 40px 2px #2173451F'
        }}
      >
        {!emailSent ? 
        <Box py={4}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            mb="1rem"
            textAlign={'center'}
          >
            Forgot Your Password ?
          </Typography>
          <Typography
            variant={'body2'}
            mb="1rem"
            textAlign={'center'}
          >
            Enter your email address and we will send you instructions to reset your password.
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '2rem' }}>
              <TextInput
                name="email"
                placeholder="Enter Your Email Address"
                control={control}
                rules={{
                    required: {
                      value: true,
                      message: 'Email is required'
                    },
                    maxLength: {
                      value: 50,
                      message: 'Email is too long'
                    },
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Email is invalid'
                    }
                }}
                type="email"
                isSubmitFocus={true}
                startIcon={<EmailIcon />}
              />
            </Box>
            <Box textAlign="center">
              <PrimaryButton
                btnText="Continue"
                formState={formState}
                isFullWidth
              />
            </Box>
          </form>
        </Box> : 
        <Box py={4}>
            <Typography
            variant={isMobile ? 'h4' : 'h3'}
            mb="1rem"
            textAlign={'center'}
            >
            Check Your Email
            </Typography>
            <Typography
            variant={'body2'}
            mb="1rem"
            textAlign={'center'}
            >
            Please check the email address {emailSentTo} for instructions to reset your password.
            </Typography>
        </Box>
        }
      </Paper>
    </AuthLayout>
  );
}
