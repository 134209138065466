import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, ChartOptions } from 'chart.js';
import { useEffect, useState } from 'react';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

// const labels = [ '10.05', '10.06', '10.07', '10.08', '10.09', '10.10', '10.11'];
// const data = {
//     labels: labels,
//     datasets: [{
//         // label: 'My First Dataset',
//         data: [15, 25, 7, 28, 37, 5, 8],
//         fill: false,
//         borderColor: '#CEE6FF',
//         tension: 0.1,
//         pointRadius: 6,
//         pointHoverRadius: 6,
//         pointBorderColor: '#3478B7',
//         pointBackgroundColor: '#3478B7'
//     }]
// };

const options: ChartOptions<'line'> = {
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false,
            position: 'nearest',
            mode: 'index',
            intersect: false,
            external: function(context) {
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }
                // Hide if no tooltip
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0';
                    return;
                }
                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }
                function getBody(bodyItem: any) {
                    return bodyItem.lines;
                }
                // Set Text
                if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);
                    let innerHtml = '<tbody>';
                    bodyLines.forEach(function(body, i) {
                        const colors = tooltipModel.labelColors[i];
                        let style = 'background: #fff';
                        style += '; display: flex';
                        style += '; flex-direction: column';
                        style += '; padding: 4px 8px';
                        style += '; justify-content: center';
                        style += '; align-items: center';
                        style += '; border: 1px solid var(--dark-disabled, rgba(48, 52, 54, 0.14))';
                        style += '; border-radius: 5px';
                        style += '; font-size: 14px';
                        style += '; box-shadow: 0px 2px 8px 0px rgba(48, 52, 54, 0.08)'
                        const span = '<span style="' + style + '"><span>' + titleLines[0] + '</span><span>' +  body + '</span></span>';
                        innerHtml += '<tr><td>' + span + '</td></tr>';
                    });
                    innerHtml += '</tbody>';
                    let tableRoot = tooltipEl.querySelector('table');
                    tableRoot!!.innerHTML = innerHtml;
                }
                const position = context.chart.canvas.getBoundingClientRect();
                // Display, position, and set styles for font
                tooltipEl.style.opacity = '1';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 20 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 50 + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            border: {
                display: false,
            },
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                color: '#30343624',
                lineWidth: 1,
            },
            border: {
                display: false,
                dash: [4,4],
                dashOffset: 10
            },
            ticks: {
                precision: 0
            }
        }
    }

}

export default function WorkOrderIntakeChart({graphsData}: any) {

    const [data, setData] = useState({} as any);

    useEffect(() => {
        if(graphsData && graphsData.wosByDate) {
            const labels = Object.keys(graphsData.wosByDate).sort();
            const vals = []
            for(let lab of labels){
                vals.push(graphsData.wosByDate[lab])
            }

            setData({
                labels: labels,
                datasets: [{
                    // label: 'My First Dataset',
                    data: vals,
                    fill: false,
                    borderColor: '#CEE6FF',
                    tension: 0.1,
                    pointRadius: 0,
                    // pointHoverRadius: 6,
                    // pointBorderColor: '#3478B7',
                    // pointBackgroundColor: '#3478B7'
                }]
            })
            
        }
    }, [graphsData]);

    return <>
        {data && data.labels && <Line data={data} options={options}/>}
    </>
}