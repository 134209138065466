import axios from "axios";
import { API_BASE_URL } from "./constants";

const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('redirectPath');
      window.location.href = "/";
    }
  }
);

export function getRequest(url: string, headers?: any) {
  return instance({
    method: "get",
    url,
    ...(headers ? headers : {}),
  }).then((res) => res);
}

export function postRequest(
  url: string,
  payload?: any,
  headers?: any
) {
  return instance({
    method: "post",
    url,
    ...(payload ? { data: payload } : {}),
    ...(headers ? headers : {}),
  }).then((res) => res);
}

export function patchRequest(url: string, payload: any, headers?: any) {
  return instance({
    method: "patch",
    url,
    data: payload,
    ...(headers ? headers : {}),
  });
}

export function putRequest(url: string, payload: any, headers?: any) {
  return instance({
    method: "put",
    url,
    data: payload,
    ...(headers ? headers : {}),
  }).then((res) => res);
}

export function deleteRequest(url: string, payload?: any, headers?: any) {
  return instance({
    method: "delete",
    url,
    ...(payload ? { data: payload } : {}),
    ...(headers ? headers : {}),
  }).then((res) => res);
}
